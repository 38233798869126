import React from "react";
import { PageLayout } from "../components/page-layout";

type Props = {
  darkmode?: boolean
  handleDarkmode?: ()=>void
}

export const NotFoundPage: React.FC<Props> = (props) => {
  return (
    <PageLayout 
      name="notfound"
      darkmode={props.darkmode!}
      handleDarkmode={props.handleDarkmode!}
    >
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Not Found
        </h1>
      </div>
    </PageLayout>
  );
};
