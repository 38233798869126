import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { PageLayout } from "../components/page-layout";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import cryptoRandomString from 'crypto-random-string';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Collapse from '@mui/material/Collapse';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { IconButton } from "@mui/material";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

interface gameInfo {
  id?: string,
  date?: string,
  type?: string,
  player1?: string,
  player2?: string,
  player3?: string,
  player4?: string,
  player5?: string,
  player6?: string,
  player7?: string,
  player8?: string,
  name?: string
  machine?: string
  dartTarget?: string
}

interface keySignature {
  [key: string]: string
}

type Parameter = {
  gameType: string
}

type Props = {
  darkmode?: boolean
  handleDarkmode?: ()=>void
  english?: boolean
  handleEnglish?: ()=>void
}

export const CreationPage: React.FC<Props> = (props) => {
  const [gameInfo, setGameInfo] = useState<gameInfo>({});
  const [error, setError] = useState<string>("");
  const [first, setFirst] = useState<boolean>(true);
  const [playerCount, setPlayerCount] = useState<number>(2);
  const [showMore, setShowMore] = useState<boolean>(false);
  const history = useHistory();
  const { gameType } = useParams<Parameter>();

  useEffect(() => {
    if (first) {
      setFirst(false);
      const newGameInfo = {...gameInfo};
      if (Number(gameType) === 1) {
        newGameInfo.dartTarget = "3000";
      }
      newGameInfo.machine = "0";
      setGameInfo(newGameInfo);
    }
  }, []);

  const createGame = async () => {
    if (!checkGameInfo()) {
      const game:keySignature = {...gameInfo}
      game.id = cryptoRandomString({length: 50});
      game.type = gameType;
      const bodyFormData = new FormData();
      for ( const key in game ) {
        bodyFormData.append(key, game[key]);
      }
      axios
      .post('/api/z70ylzpf1to6.php', bodyFormData)
      .then(response => {
        //redirect zur game page
        if (response.data.id !== undefined) {
          history.push('/game/'+response.data.id);
        } else {
          setError("Response is undefined: "+String(response.data));
        }
        
      })
      .catch(function(error) {
        console.log(error)
      });    
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newGameInfo = {...gameInfo};
    switch(e.target.id) {
      case "input_player1":
        newGameInfo.player1 = e.target.value
        break;
      case "input_player2":
        newGameInfo.player2 = e.target.value
        break;
      case "input_player3":
        newGameInfo.player3 = e.target.value
        break;
      case "input_player4":
        newGameInfo.player4 = e.target.value
        break;
      case "input_player5":
        newGameInfo.player5 = e.target.value
        break;
      case "input_player6":
        newGameInfo.player6 = e.target.value
        break;
      case "input_player7":
        newGameInfo.player7 = e.target.value
        break;
      case "input_player8":
        newGameInfo.player8 = e.target.value
        break;
      case "input_name":
        newGameInfo.name = e.target.value
        break;
    }
    setGameInfo(newGameInfo);
  };

  const handleChangeMachine = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newGameInfo = {...gameInfo};
    newGameInfo.machine = (event.target as HTMLInputElement).value;
    setGameInfo(newGameInfo);
  };

  const handleChangeDart = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newGameInfo = {...gameInfo};
    newGameInfo.dartTarget = (event.target as HTMLInputElement).value;
    setGameInfo(newGameInfo);
  };

  const checkGameInfo = ():boolean => {
    let counter = 0
    if (gameInfo.player1! && gameInfo.player1.length > 0) {
      counter++
    } 
    if (gameInfo.player2! && gameInfo.player2.length > 0) {
      counter++
    } 
    if (gameInfo.player3! && gameInfo.player3.length > 0) {
      counter++
    } 
    if (gameInfo.player4! && gameInfo.player4.length > 0) {
      counter++
    } 
    if (gameInfo.player5! && gameInfo.player5.length > 0) {
      counter++
    } 
    if (gameInfo.player6! && gameInfo.player6.length > 0) {
      counter++
    } 
    if (gameInfo.player7! && gameInfo.player7.length > 0) {
      counter++
    } 
    if (gameInfo.player8! && gameInfo.player8.length > 0) {
      counter++
    } 
    if (counter === 0) {
      setError("Bitte mindestens einen Spieler ausfüllen")
      return true    
    } else {
      setError("");
    }
    //todo check ob SELECT DELETE etc in frontend
    return false
  };
  return (
    <PageLayout 
      name="creation"
      darkmode={props.darkmode!}
      handleDarkmode={props.handleDarkmode!}
      english={props.english!}
      handleEnglish={props.handleEnglish!}
    >
      <Box textAlign='center' sx={{ p: 2 }}>
        <Typography variant="h5" gutterBottom>
          
          {props.english!?"Create new game":"Neues Spiel anlegen"}
        </Typography>
      </Box>
      {error.length > 0 &&
        <Box textAlign='center' sx={{ p: 2 }}>
          <Alert severity="error">{error}</Alert>
          
        </Box>
      }
      <Box textAlign='center' sx={{ p: 2 }}>
        <AccountCircle sx={{ color: 'action.active', mr: 1, my: 1 }} />
        <TextField 
          id="input_player1" 
          label={props.english!?"player 1":"Spieler 1"}
          onChange={handleChange} />
      </Box>
      <Box textAlign='center' sx={{ p: 2 }}>
        <AccountCircle sx={{ color: 'action.active', mr: 1, my: 1 }} />
        <TextField 
          id="input_player2" 
          label={props.english!?"player 2":"Spieler 2"}
          onChange={handleChange} />
      </Box>
      {playerCount >= 3 && 
      <Box textAlign='center' sx={{ p: 2 }}>
        <AccountCircle sx={{ color: 'action.active', mr: 1, my: 1 }} />
        <TextField 
          id="input_player3" 
          label={props.english!?"player 3":"Spieler 3"}
          onChange={handleChange} />
      </Box>
      }
      {playerCount >= 4 && 
      <Box textAlign='center' sx={{ p: 2 }}>
        <AccountCircle sx={{ color: 'action.active', mr: 1, my: 1 }} />
        <TextField 
          id="input_player4" 
          label={props.english!?"player 4":"Spieler 4"}
          onChange={handleChange} />
      </Box>
      }
      {playerCount >= 5 && 
      <Box textAlign='center' sx={{ p: 2 }}>
        <AccountCircle sx={{ color: 'action.active', mr: 1, my: 1 }} />
        <TextField 
          id="input_player5" 
          label={props.english!?"player 5":"Spieler 5"}
          onChange={handleChange} />
      </Box>
      }
      {playerCount >= 6 && 
      <Box textAlign='center' sx={{ p: 2 }}>
        <AccountCircle sx={{ color: 'action.active', mr: 1, my: 1 }} />
        <TextField 
          id="input_player6" 
          label={props.english!?"player 6":"Spieler 6"}
          onChange={handleChange} />
      </Box>
      }
      {playerCount >= 7 && 
      <Box textAlign='center' sx={{ p: 2 }}>
        <AccountCircle sx={{ color: 'action.active', mr: 1, my: 1 }} />
        <TextField 
          id="input_player7" 
          label={props.english!?"player 7":"Spieler 7"}
          onChange={handleChange} />
      </Box>
      }
      {playerCount >= 8 && 
      <Box textAlign='center' sx={{ p: 2 }}>
        <AccountCircle sx={{ color: 'action.active', mr: 1, my: 1 }} />
        <TextField 
          id="input_player8" 
          label={props.english!?"player 8":"Spieler 8"}
          onChange={handleChange} />
      </Box>
      }
      {playerCount < 8 && 
      <Box textAlign='center' sx={{ p: 2 }}>
        <IconButton aria-label={props.english!?"add player":"Spieler hinzufügen"} onClick={()=>setPlayerCount(playerCount+1)}>
          <PersonAddAlt1Icon color="secondary"/>
        </IconButton>
      </Box>
      }

      {Number(gameType) === 1 && 
        <Box textAlign='center' sx={{ p: 2 }}>

          <FormControl>
            <FormLabel id="dart-label">{props.english!?"aim score":"Zielpunkte"}</FormLabel>
            <RadioGroup
              row
              aria-labelledby="dart-label"
              name="dart-group"
              value={gameInfo.dartTarget!?gameInfo.dartTarget:"3000"}
              onChange={handleChangeDart}
            >
              <FormControlLabel value="3000" control={<Radio />} label="3000" />
              <FormControlLabel value="6000" control={<Radio />} label="6000" />
              <FormControlLabel value="9000" control={<Radio />} label="9000" />
            </RadioGroup>
          </FormControl>
        </Box>
      }

      <Collapse in={showMore}>
        <Box textAlign='center' sx={{ p: 2 }}>
          <ContentPasteIcon sx={{ color: 'action.active', mr: 1, my: 1 }} />
          <TextField 
            id="input_name" 
            label={props.english!?"game name":"Spielname"}
            onChange={handleChange} />
        </Box>
        <Box textAlign='center' sx={{ p: 2 }}>
          <FormControl>
            <FormLabel id="machine-label">{props.english!?"machine number":"Spielbahn"}</FormLabel>
            <RadioGroup
              row
              aria-labelledby="machine-label"
              name="machine-group"
              value={gameInfo.machine!?gameInfo.machine:"-"}
              onChange={handleChangeMachine}
            >
              <FormControlLabel value="0" control={<Radio />} label="-" />
              <FormControlLabel value="1" control={<Radio />} label="1" />
              <FormControlLabel value="2" control={<Radio />} label="2" />
              <FormControlLabel value="3" control={<Radio />} label="3" />
            </RadioGroup>
          </FormControl>
        </Box>
      </Collapse>

      <Box textAlign='center' sx={{ p: 2 }}>
        <Button onClick={()=>setShowMore(!showMore)}>{props.english!?(showMore?"show less":"show more"):(showMore?"weniger Eingaben":"weitere Eingaben")}</Button>
      </Box>

      {(Number(gameType) === 0 || Number(gameType) === 1) &&
        <Box textAlign='center' sx={{ p: 2 }}>
          <Button
            variant={props.darkmode!?"contained":"outlined"}  
            size="large" 
            onClick={createGame}>
            {props.english!?"create game":"Anlegen"}
          </Button>
        </Box>      
      }


    </PageLayout>
  );
};
