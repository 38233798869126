import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import Button from '@mui/material/Button';

interface Props {
  darkmode?:boolean
}

export const LogoutButton: React.FC<Props> = ({darkmode}) => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      returnTo: window.location.origin,
    });
  };

  return (
    <Button variant={darkmode!?"contained":"outlined"} size="small" className="button__logout" onClick={handleLogout}>
      Logout
    </Button>
  );
};
