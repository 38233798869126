import React, {useState, useEffect} from "react";
import { PageLayout } from "../components/page-layout";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  getLocalStorage,
  setLocalStorage
} from './../components/localStorage/localStorage'
import {SkeeboardLocalStorage} from '../models/skeeboard'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import {convertDateTime} from '../components/functions/datetime';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

type Props = {
  darkmode?: boolean
  handleDarkmode?: ()=>void
  english?: boolean
  handleEnglish?: ()=>void
}

export const HistoryPage: React.FC<Props> = (props) => {

  const [first, setFirst] = useState<boolean>(true);
  const [storage, setStorage] = useState<SkeeboardLocalStorage | undefined>(undefined)

  useEffect(() => {
    if (first) {
      setFirst(false);
      setStorage(getLocalStorage())
    }
  }, []);

  const handleDelete = (id: string) => {
    const newStorage = {...storage!}
    newStorage.games = newStorage.games?.filter(elem => elem.id !== id)
    setStorage(newStorage);
    setLocalStorage(newStorage);
    setFirst(true);
  };

  return (
    <PageLayout 
      name="history"
      darkmode={props.darkmode!}
      handleDarkmode={props.handleDarkmode!}
      english={props.english!}
      handleEnglish={props.handleEnglish!}
    >
        <Box textAlign='center' sx={{ p: 2 }}>
          <Typography variant="h5" gutterBottom>
          {props.english!?"History":"Historie"}
          </Typography>
        </Box>
          {storage! && storage.games.length > 0 &&
            <TableContainer>
              <Table aria-label={props.english!?"player":"previous games"}>
                <TableHead>
                  <TableRow>
                    <TableCell>{props.english!?"creation date":"Erstellungsdatum"}</TableCell>
                    <TableCell>{props.english!?"player":"Spieler"}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {storage.games.map((row) => (
                    <TableRow
                      key={row.id!}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      {/* <TableCell>{convertDateTime(row.datetime!)}</TableCell> */}
                      <TableCell>{convertDateTime(row.datetime!, false)}</TableCell>
                      <TableCell component="th" scope="row">
                        {row.player!.map((player, i) => (
                          i === 0 ? player : ", "+player
                        ))}
                      </TableCell>
                      <TableCell>  
                        <Button 
                          component={Link} 
                          to={"/game/"+row.id!} 
                          variant={props.darkmode!?"contained":"outlined"} 
                          startIcon={<ContentPasteIcon/>}>
                          {props.english!?"to the game":"zum Spiel"}
                        </Button>
                        <IconButton aria-label={props.english!?"delete":"Löschen"} size="large" onClick={()=>handleDelete(row.id!)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }     

          {storage! && storage.games.length <= 0 &&
            <Box textAlign='center' sx={{ p: 2 }}>
              {props.english!?"no entries":"keine Einträge vorhanden"}
            </Box>          
          }             

    </PageLayout>
  );
};
