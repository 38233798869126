// import { useAuth0 } from "@auth0/auth0-react";
import React, {useState, useEffect} from "react";
import { Route, Switch } from "react-router-dom";
import { PageLoader } from "./components/page-loader";
import { ProtectedRoute } from "./components/protected-route";
import { AdminPage } from "./pages/admin-page";
import { CallbackPage } from "./pages/callback-page";
import { CreationPage } from "./pages/creation-page";
import { GamePage } from "./pages/game-page";
import { HomePage }  from "./pages/home-page";
import { NotFoundPage } from "./pages/not-found-page";
import { ProtectedPage } from "./pages/protected-page";
import { PublicPage } from "./pages/public-page";
import { InformationPage } from "./pages/information-page";
import { Searchtable } from "./pages/searchtable-page";
import { HistoryPage } from "./pages/history-page";
import { CompetitionPage } from "./pages/competition-page";
import { ThemeProvider, Theme } from '@mui/material/styles';
import { darkmodeTheme, whitemodeTheme } from './components/themes/themes';
import { getLocalStorage, setLocalStorage } from './components/localStorage/localStorage'
import { SkeeboardLocalStorage } from './models/skeeboard'
import { CssBaseline } from '@mui/material/';


export const App: React.FC = () => {
  // const { isLoading } = useAuth0();
  const [darkmode, setDarkmode] = useState<boolean>(false);
  const [english, setEnglish] = useState<boolean>(false);
  const [theme, setTheme] = useState<Theme>(whitemodeTheme);
  const [storage, setStorage] = useState<SkeeboardLocalStorage | undefined>(undefined);

  // if (isLoading) {
  //   return (
  //     <div className="page-layout">
  //       <PageLoader />
  //     </div>
  //   );
  // }

  useEffect(() => {
    if (storage!) {
      if (darkmode) {
        setTheme(darkmodeTheme)
      } else {
        setTheme(whitemodeTheme)
      }
      storage.darkmode = darkmode;
      setLocalStorage(storage)
    } else {
      const lstorage = getLocalStorage();
      setStorage(lstorage);
      if (lstorage.darkmode) {
        setDarkmode(true);
      } else {
        setDarkmode(false);
      }
    }
  }, [darkmode]);

  const handleDarkmode = () => {
    setDarkmode(!darkmode);
  }

  useEffect(() => {
    if (storage!) {
      if (storage.english === undefined) {
        storage.english = false
      }
      setLocalStorage(storage)
    } else {
      const lstorage = getLocalStorage();
      setStorage(lstorage);
      if (lstorage.english) {
        setEnglish(true);
      } else {
        setEnglish(false);
      }
    }
  }, [english]);

  const handleEnglish = () => {
    setEnglish(!english);
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Switch>
        <Route exact path="/"><HomePage darkmode={darkmode} handleDarkmode={handleDarkmode} english={english} handleEnglish={handleEnglish}/></Route>
        <Route path="/home"><HomePage darkmode={darkmode} handleDarkmode={handleDarkmode} english={english} handleEnglish={handleEnglish}/></Route>
        <Route path="/index"><HomePage darkmode={darkmode} handleDarkmode={handleDarkmode} english={english} handleEnglish={handleEnglish}/></Route>
        <Route path="/index.html"><HomePage darkmode={darkmode} handleDarkmode={handleDarkmode} english={english} handleEnglish={handleEnglish}/></Route>
        <Route path="/public"><PublicPage darkmode={darkmode} handleDarkmode={handleDarkmode}/></Route>
        <Route path="/information"><InformationPage darkmode={darkmode} handleDarkmode={handleDarkmode} english={english} handleEnglish={handleEnglish}/></Route>
        <Route path="/creation/:gameType"><CreationPage darkmode={darkmode} handleDarkmode={handleDarkmode} english={english} handleEnglish={handleEnglish}/></Route>
        <Route path="/history"><HistoryPage darkmode={darkmode} handleDarkmode={handleDarkmode} english={english} handleEnglish={handleEnglish}/></Route>
        <Route path="/game/:id"><GamePage darkmode={darkmode} handleDarkmode={handleDarkmode} english={english} handleEnglish={handleEnglish}/></Route>
        <Route path="/competition/:ids"><CompetitionPage darkmode={darkmode} handleDarkmode={handleDarkmode}/></Route>
        <Route path="/callback" component={CallbackPage} />
        <ProtectedRoute path="/protected" component={ProtectedPage} />
        <ProtectedRoute path="/searchtable" component={Searchtable} />
        {/* <Route path="/st" component={Searchtable} /> */}
        <ProtectedRoute path="/admin" component={AdminPage} />
        <Route path="*"><NotFoundPage darkmode={darkmode} handleDarkmode={handleDarkmode}/></Route>
      </Switch>
    </ThemeProvider>
  );
};
