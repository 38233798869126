import React from "react";
import { NavBar } from "./navigation/nav-bar";
// import { NavBar } from "./navigation/desktop/nav-bar";
// import { MobileNavBar } from "./navigation/mobile/mobile-nav-bar";
import { PageFooter } from "./page-footer";
import {SkeeboardPlayerButton} from "../models/skeeboard"

interface PageLayoutProps {
  children?: React.ReactNode;
  name?:string
  darkmode?:boolean
  handleDarkmode?:()=>void
  english?:boolean
  handleEnglish?:()=>void
  gameHandleShowNames?:()=>void
  gameShowNames?:boolean
  gameHandleDialogQROpen?:()=>void
  gameHandleShowPlayerButton?:(player:number)=>void
  gamePlayerButton?:SkeeboardPlayerButton[]
}

export const PageLayout: React.FC<PageLayoutProps> = ({ 
  name, 
  darkmode,
  handleDarkmode,
  english,
  handleEnglish,
  gameHandleShowNames, 
  gameShowNames, 
  gameHandleDialogQROpen, 
  gameHandleShowPlayerButton,
  gamePlayerButton,
  children }) => {
  return (
    <div className="page-layout">
      <NavBar 
        name={name} 
        darkmode={darkmode}
        handleDarkmode={handleDarkmode}
        english={english}
        handleEnglish={handleEnglish}
        gameHandleShowNames={gameHandleShowNames} 
        gameShowNames={gameShowNames}
        gameHandleDialogQROpen={gameHandleDialogQROpen}
        gameHandleShowPlayerButton={gameHandleShowPlayerButton}
        gamePlayerButton={gamePlayerButton}
      />
      {/* <MobileNavBar /> */}
      <div className="page-layout__content">{children}</div>
      <PageFooter />
    </div>
  );
};
