import React, { useState } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Picker, {PickerValue} from '../picker/picker';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import {SkeeboardDialog} from '../../models/skeeboard'

export interface DialogProps {
  open: boolean;
  dialogItems: SkeeboardDialog;
  onClose: (save: boolean, value: number | null, dialogItems: SkeeboardDialog) => void;
  darkmode: boolean;
  english: boolean;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export default function ScoreDialog({open, dialogItems, onClose, darkmode, english}:DialogProps) {
  // const { onClose, selectedValue, open } = props;
  const [value, setValue] = useState<PickerValue | null>(null)

  if (value === null) {
    if (dialogItems.value === null) {
      setValue({first:1,second:5,third:0});
    } else {
      const digits = dialogItems.value!.toString().split('');
      while (digits.length < 3) {
        digits.unshift("0");
      }
      setValue({first:Number(digits[0]),second:Number(digits[1]),third:Number(digits[2])});
    }
  }

  function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme:any) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  const handleClose = () => {
    onClose(false, null, dialogItems);
  };

  const handleSave = () => {
    if (value!) {
      onClose(true, Number(String(value.first!)+String(value.second!)+String(value.third!)), dialogItems);
    }
    
  };

  const handleClear = () => {
    onClose(true, null, dialogItems);
  };

  const handleValue = (val:PickerValue) => {
    if (val.first!) {
      const newVal = {...value};
      newVal.first = val.first;
      setValue(val);
    }
    if (val.second!) {
      const newVal = {...value};
      newVal.second = val.second;
      setValue(val);
    }
    if (val.third!) {
      const newVal = {...value};
      newVal.third = val.third;
      setValue(val);
    }
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <BootstrapDialogTitle id="dialog-title" onClose={handleClose}>
          {english!?"scoring":"Punktevergabe"}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Picker value={value!} handleValue={handleValue} darkmode={darkmode}/>
        {/* <Button onClick={()=>console.log(value)}>Punkte</Button> */}
      </DialogContent>
      <DialogActions style={dialogItems.showClear?{justifyContent: "space-between"}:{alignItems: "end"}}>
        {dialogItems.showClear && <Button variant={darkmode!?"contained":"outlined"} onClick={handleClear}>{english!?"reset":"Zurücksetzen"}</Button>}
        <Button  variant={darkmode!?"contained":"outlined"} autoFocus onClick={handleSave}>{english!?"save":"Speichern"}</Button>
      </DialogActions>
    </Dialog>
  );
}