import React from 'react';

export function convertDateTime(dateTime:string, showTime:boolean):(string) {
    if (dateTime!.length > 0 ) {
        const arrDateTime = dateTime.split(" ");
        const date = arrDateTime[0];
        const time = arrDateTime[1];
        if (showTime) {
           return date.substring(8, 10)+"."+date.substring(5, 7)+"."+date.substring(0, 4)+" - "+time;
        } else {
           return date.substring(8, 10)+"."+date.substring(5, 7)+"."+date.substring(0, 4); 
        }
    } 
    return "";
}
