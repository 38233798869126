import React, { useState } from "react";
import WheelPicker from "react-simple-wheel-picker";
import { styled } from '@mui/system';
import { makeStyles } from "@mui/styles";
import { whitemodeTheme } from "../themes/themes";

const useStyles = makeStyles(() => ({
  whiteMode: {
    "& li p": {
      fontSize: "1.5rem !important",
    },
  },
  darkMode: {
    "& ul": {
      background: "#383838",
    },
    "& li p": {
      fontSize: "1.5rem !important",
    },
    '& [aria-selected="true"]': {
      "& p ": {
        color: "#ffffff !important" ,
        fontSize: 15,
      }
    },   
    '@global': {
      '*::-webkit-scrollbar': {
        width: '0.5em'
      },
      '*::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.3)',
      }
    }
  }
}));

interface Options{
  first: number[]
  second: number[]
  third: number[]
}

interface OptionGroups{
  first?: OptionGroupsItem[]
  second?: OptionGroupsItem[]
  third?: OptionGroupsItem[]
}

interface OptionGroupsItem{
    id: string,
    value: number
}

export interface PickerValue {
  first: number | undefined
  second: number | undefined
  third: number | undefined
}

export interface PickerProps {
  value:PickerValue;
  handleValue:(val:PickerValue)=>void;
  darkmode:boolean;
}

export default function Picker({value, handleValue, darkmode}:PickerProps) {

  // const [opGroups, setOpGroups] = useState<OptionGroups | undefined>(undefined)
  const [pickerColumn, setPickerColumn] = useState<any | undefined>(undefined)
  const [first, setFirst] = useState<boolean>(true)
  const styles = useStyles();
 
  const newOptionGroups = (optionGroups:Options) => {
    const groups:OptionGroups = {}
    let key: keyof Options;
    for (key in optionGroups) {
        groups[key] = setKeyValue(optionGroups[key]);
    }
    return groups;
  };

  const optionGroups:Options = {
    first: [0,1,2,3,4,5,6,7,8,9],
    second: [0,1,2,3,4,5,6,7,8,9],
    third: [0]
  };
  
  const emptyPicker:PickerValue = {
    first: undefined,
    second: undefined,
    third: undefined
  };
  

  const setKeyValue = (arr:number[]) => {
    return arr.map((item) => {
      const dataSet:OptionGroupsItem = {
        id: String(item),
        value: item
      };
      return dataSet;
    });
  };

  const handleOnChangeDefault = (target: any) => {
    const notUsedButNecessary = () => void 0;
  };

  const handleOnChangeFirst = (target:OptionGroupsItem) => {
    const newValue:PickerValue = emptyPicker
    newValue.first = target.value
    handleValue(newValue)
  };

  const handleOnChangeSecond = (target:OptionGroupsItem) => {
    const newValue:PickerValue = emptyPicker
    newValue.second = target.value
    handleValue(newValue)
  };

  const handleOnChangeThird = (target:OptionGroupsItem) => {
    const newValue:PickerValue = emptyPicker
    newValue.third = target.value
    handleValue(newValue)
  };

  if (first) {
    setFirst(false);
    const NewPickerColumn = [];
    const opGroups = newOptionGroups(optionGroups)
    let group: keyof Options;
    for (group in opGroups) {
      const data = opGroups![group];
      let defaultValue:OptionGroupsItem = {id:String(0), value:0}
      let onChangeFunction = handleOnChangeDefault
      switch(group) {
        case "first":
          defaultValue={id:String(value.first), value:value.first!}
          onChangeFunction = handleOnChangeFirst
          break;
        case "second":
          defaultValue={id:String(value.second), value:value.second!}
          onChangeFunction = handleOnChangeSecond
          break;
        case "third":
          defaultValue={id:String(value.third), value:value.third!}
          onChangeFunction = handleOnChangeThird
          break;
      }
      
      NewPickerColumn.push(
        <DarkmodeWheelPicker
          data={data!}
          onChange={onChangeFunction}
          height={180}
          width={100}
          titleText="Punktvergabe"
          itemHeight={20}
          // selectedID={data![0].id}
          selectedID={defaultValue!.id}
          color="#999999"
          activeColor="#000"
          backgroundColor="white"
          shadowColor="none"
        />
      ); 
    }
    setPickerColumn(NewPickerColumn);

  }

  return ( <>
    {darkmode && 
      <div className={`Picker ${styles.darkMode}`}>
        <PickerContainer>{pickerColumn}</PickerContainer>
      </div>
    }
    {!darkmode && 
      <div className={`Picker ${styles.whiteMode}`}>
        <PickerContainer>{pickerColumn}</PickerContainer>
      </div>
    }
    </>
  );
}

const PickerContainer = styled('div')({

});

const DarkmodeWheelPicker = styled(WheelPicker)({
  background: 'red',
  color: 'green'
});
