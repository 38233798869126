import React, { useEffect, useState } from "react";
import { PageLayout } from "../components/page-layout";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SkeeboardGame } from "src/models/skeeboard";
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import {convertDateTime} from '../components/functions/datetime';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import { useTheme } from '@mui/material/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export const Searchtable: React.FC = () => {
  const [message, setMessage] = useState<string>("");

  const [first, setFirst] = useState<boolean>(true);
  const [games, setGames] = useState<SkeeboardGame[]>([]);
  const [competition, setCompetition] = useState<SkeeboardGame[]>([]);
  const [compLink, setCompLink] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const [searchDate, setSearchDate] = useState<boolean>(false);
  const [playername, setPlayername] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    if (first) {
      const newDate = new Date()
      setDate(newDate.getFullYear()+'-'+('0'+(newDate.getMonth()+1)).slice(-2)+'-'+('0'+newDate.getDate()).slice(-2));
      setFirst(false);
    }
  }, []);

  const selectGame = () => {
    console.log("selectGame")
    setPage(0);
    if (playername.length > 0 || name.length > 0 || searchDate) {
      const bodyFormData = new FormData();
      if (playername.length > 0) {
        bodyFormData.append("playername", playername);
      }
      if (name.length > 0) {
        bodyFormData.append("name", name);
      }
      if (searchDate) {
        bodyFormData.append("date", date);
      }
      axios
      .post('/api/zh4tulunwcse.php', bodyFormData)
      .then(response => {
        console.log(response.data)
        setGames(response.data);
      })
      .catch(function(error) {
        console.log(error)
      });    
    }
  };

  const deleteGame = (id:string) => {
    if (id.length > 0 ) {
      const bodyFormData = new FormData();
      bodyFormData.append("id", id);
      axios
      .post('/api/zbxo4eb0avre.php', bodyFormData)
      .then(response => {
        console.log(response.data)
      })
      .catch(function(error) {
        console.log(error)
      });    
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch(e.target.id) {
      case "playername":
        setPlayername(e.target.value);
        break;
      case "name":
        setName(e.target.value);
        break;
      case "date":
        setDate(e.target.value);
        break;
      case "searchDate":
        setSearchDate(!searchDate);
        break;
    }
  };

  const finalGame = (id:string) => {
    if (id.length > 0 ) {
      const bodyFormData = new FormData();
      bodyFormData.append("id", id);
      axios
      .post('/api/zd1kyalugn05.php', bodyFormData)
      .then(response => {
        console.log(response.data)
      })
      .catch(function(error) {
        console.log(error)
      });    
    }
  };

  const unfinalGame = (id:string) => {
    if (id.length > 0 ) {
      const bodyFormData = new FormData();
      bodyFormData.append("id", id);
      axios
      .post('/api/zosqbmekhrkf.php', bodyFormData)
      .then(response => {
        console.log(response.data)
      })
      .catch(function(error) {
        console.log(error)
      });    
    }
  };

  const addCompetition = (game:SkeeboardGame) => {
    if (game.id.length > 0 ) {
      const newComp = competition
      newComp.push(game)
      setNewCompLink(newComp)
      setCompetition(newComp)
    }
  };

  const removeCompetition = (index:number) => {
    if (index >= 0 ) {
      const newComp = competition
      newComp.splice(index, 1)
      setNewCompLink(newComp)
      setCompetition(newComp)
    }
  };

  const setNewCompLink = (list:SkeeboardGame[]) => {
    let newCompLink = ""
    list.forEach(function (item) {
      newCompLink = newCompLink + "_" + item.id
    });
    setCompLink(newCompLink)
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - games.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
  <PageLayout>
    <Box textAlign='center' sx={{ p: 2 }}>
      <Typography variant="h5" gutterBottom>
      Suchtabelle
      </Typography>
    </Box>

    <Box textAlign='center' sx={{ p: 2 }}>
      <Grid container spacing={2}>
        {/* <form onSubmit={(e) => {
          e.preventDefault();
          selectGame();
        }}> */}
          <Grid item xs={8}>
            <Stack direction="row" spacing={2} justifyContent="center">
              <TextField 
                id="date" 
                label="Datum" 
                type="date"
                size="small"
                value={date}
                onChange={handleChange} />

              <FormControlLabel control={
                <Checkbox 
                  id="searchDate"
                  value={searchDate} 
                  onChange={handleChange}
                  size="small"
                  />
              } label="aktiv" />
            </Stack>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
              justifyContent="center"
              sx={{ m: 2 }} 
            >
              <TextField 
                id="playername" 
                label="Spieler" 
                size="small"
                onChange={handleChange} />

              <TextField 
                id="name" 
                label="Spiel" 
                size="small"
                onChange={handleChange} />
            </Stack>    
          </Grid> 
        {/* </form>    */}
        <Grid item xs={4}>
          <Button
            variant="outlined" 
            size="large" 
            onClick={selectGame}>
            Suchen
          </Button>
        </Grid>
      </Grid>
    </Box> 

    {competition! && competition.length > 0 && <List dense={true}>
              {competition.map((comp, index) => (
                <ListItem
                  key={index}
                  secondaryAction={
                    <Tooltip title={"löschen"}>
                      <IconButton edge="end" aria-label="löschen" onClick={()=>removeCompetition(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <VideogameAssetIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      comp.p1_name! && comp.p1_name.length > 0 ? comp.p1_name : "" + 
                      comp.p2_name! && comp.p2_name.length > 0 ? " | "+comp.p2_name : "" +
                      comp.p3_name! && comp.p3_name.length > 0 ? " | "+comp.p3_name : "" +
                      comp.p4_name! && comp.p4_name.length > 0 ? " | "+comp.p4_name : "" 
                    }
                    secondary={convertDateTime(comp.datetime!, true)}
                  />
                </ListItem>
              ))}
              {competition.length > 0 &&
                <ListItem>
                  <Button target="_blank" component={Link} to={"/competition/"+compLink} variant="outlined" startIcon={<EmojiEventsIcon/>}> 
                    Turnier erstellen
                  </Button>
              </ListItem>
              }
            </List>}

    {games! && games.length > 0 &&
      <TableContainer>
        <Table aria-label="vorherige Spiele">
          <TableHead>
            <TableRow>
              <TableCell>Erstellungsdatum</TableCell>
              <TableCell>Spieler</TableCell>
              <TableCell>Spielname</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? games.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : games
            ).map((row) => (
              <TableRow
                key={row.id!}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{convertDateTime(row.datetime!, true)}</TableCell>
                <TableCell>
                  <List>
                  {row.p1_name! && row.p1_name.length > 0 ? <ListItem>{row.p1_name}</ListItem> : ""}
                  {row.p2_name! && row.p2_name.length > 0 ? <ListItem>{row.p2_name}</ListItem> : ""}
                  {row.p3_name! && row.p3_name.length > 0 ? <ListItem>{row.p3_name}</ListItem> : ""}
                  {row.p4_name! && row.p4_name.length > 0 ? <ListItem>{row.p4_name}</ListItem> : ""}
                  </List>
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>  
                  <Button variant="outlined" startIcon={<EmojiEventsIcon/>} onClick={()=>addCompetition(row)} >
                    Turnierteilnehmer
                  </Button>
                  <Button target="_blank" component={Link} to={"/game/"+row.id!} variant="outlined" startIcon={<ContentPasteIcon/>}>
                    Spiel im Tab öffnen
                  </Button>
                  {Number(row.final) === 1 && 
                  <Tooltip title="Entsperren">
                    <IconButton aria-label="Entsperren" size="large" onClick={()=>unfinalGame(row.id!)}>
                      <LockOpenIcon />
                    </IconButton>
                  </Tooltip>
                  }
                  {Number(row.final) === 0 && 
                  <Tooltip title="Sperren">
                    <IconButton aria-label="Sperren" size="large" onClick={()=>finalGame(row.id!)}>
                      <LockIcon />
                    </IconButton>
                  </Tooltip>
                  }
                  <Tooltip title="Löschen">
                    <IconButton aria-label="Löschen" size="large" onClick={()=>deleteGame(row.id!)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 100]}
              colSpan={4}
              count={games.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
        </Table>
      </TableContainer>
    }     

    {games! && games.length <= 0 &&
      <Box textAlign='center' sx={{ p: 2 }}>
        Bitte die Suche ausführen
      </Box>          
    }             

  </PageLayout>
  );
};
