import React, { useEffect, useState } from "react";
import { PageLayout } from "../components/page-layout";
import { useParams } from 'react-router-dom';
import axios from 'axios'
import {
  SkeeboardGame, 
  SkeeboardScore, 
  SkeeboardDialog, 
  SkeeboardPlayerButton,
  SkeeboardLocalStorageGame
} from '../models/skeeboard'
import {
  getLocalStorage,
  setLocalStorage
} from './../components/localStorage/localStorage'
import { instanceOfSkeeboardScore } from '../components/functions/game'
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ScoreDialog from "src/components/dialog/scoredialog";
import QRDialog from "src/components/dialog/qrdialog";
import Typography from '@mui/material/Typography';
import FunctionsIcon from '@mui/icons-material/Functions';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import Box from '@mui/material/Box';
import LockIcon from '@mui/icons-material/Lock';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { styled } from '@mui/material/styles';

type Parameter = {
  id: string
}

interface sumsAverageType {
  sum1: number;
  sum2: number;
  sum3: number;
  sum4: number;
  sum5: number;
  sum6: number;
  sum7: number;
  sum8: number;
  ave1: number;
  ave2: number;
  ave3: number;
  ave4: number;
  ave5: number;
  ave6: number;
  ave7: number;
  ave8: number;
}
type Props = {
  darkmode?: boolean
  handleDarkmode?: ()=>void
  english?: boolean
  handleEnglish?: ()=>void
}



export const GamePage: React.FC<Props> = (props) => {
  const [message, setMessage] = useState<string>("");
  const [game, setGame] = useState<SkeeboardGame>();
  const [showNames, setShowNames] = useState<boolean>(true);
  const { id } = useParams<Parameter>();
  const [rounds, setRounds] = useState<Array<number>>([]);
  const defaultSumAverage = {sum1:0,sum2:0,sum3:0,sum4:0,sum5:0,sum6:0,sum7:0,sum8:0,ave1:0,ave2:0,ave3:0,ave4:0,ave5:0,ave6:0,ave7:0,ave8:0}
  const [sumAve, setSumAve] = useState<sumsAverageType>(defaultSumAverage)

  const [openDialogScore, setOpenDialogScore] = React.useState<boolean>(false);
  const [openDialogQR, setOpenDialogQR] = React.useState<boolean>(false);
  
  const [dialog, setDialog] = React.useState<SkeeboardDialog>({player: 0, round: 0, value: null, showClear:true});

  const [playerButton, setPlayerButton] = React.useState<SkeeboardPlayerButton[] | undefined>(undefined);
  const [isMobile, setIsMobile] = useState<boolean>((window.innerWidth<720)?true:false);
  const [toLocalStorage, setToLocalStorage] = useState<boolean>(false)
  const [final, setFinal] = useState<boolean>(false)

  const [dartPoints, setDartPoints] = useState<number>(0)
  const [dartError, setDartError] = useState<boolean>(false)

  const [refreshBoard, setRefreshBoard] = useState<boolean>(false)

  const qrText = window.location.href;
  const roundsDefault = [1,2,3,4,5,6,7,8,9,10]

  useEffect(() => {
    getGame(true);
  }, [id]);

  useEffect(() => {
    if (refreshBoard) {
      setRefreshBoard(false)
      //wait one sec for the db update
      setTimeout(() => {getGame(false)}, 1000)
    }
  }, [refreshBoard]);

  useEffect(() => {
    if (game!) {
      if (game.type === 0) {
        setRounds(roundsDefault)
        let finalCounter = 0;
        let finalAim = 0;
        if (game.p1_name! && game.p1_name.length > 0) {
          finalAim += roundsDefault.length
          roundsDefault.forEach(round => {
            if (getRoundValue(game.p1_score, round) !== null) {
              finalCounter += 1
            }
          });
        }
        if (game.p2_name! && game.p2_name.length > 0) {
          finalAim += roundsDefault.length
          roundsDefault.forEach(round => {
            if (getRoundValue(game.p2_score, round) !== null) {
              finalCounter += 1
            }
          });
        }
        if (game.p3_name! && game.p3_name.length > 0) {
          finalAim += roundsDefault.length
          roundsDefault.forEach(round => {
            if (getRoundValue(game.p3_score, round) !== null) {
              finalCounter += 1
            }
          });
        }
        if (game.p4_name! && game.p4_name.length > 0) {
          finalAim += roundsDefault.length
          roundsDefault.forEach(round => {
            if (getRoundValue(game.p4_score, round) !== null) {
              finalCounter += 1
            }
          });
        }
        if (game.p5_name! && game.p5_name.length > 0) {
          finalAim += roundsDefault.length
          roundsDefault.forEach(round => {
            if (getRoundValue(game.p5_score, round) !== null) {
              finalCounter += 1
            }
          });
        }
        if (game.p6_name! && game.p6_name.length > 0) {
          finalAim += roundsDefault.length
          roundsDefault.forEach(round => {
            if (getRoundValue(game.p6_score, round) !== null) {
              finalCounter += 1
            }
          });
        }
        if (game.p7_name! && game.p7_name.length > 0) {
          finalAim += roundsDefault.length
          roundsDefault.forEach(round => {
            if (getRoundValue(game.p7_score, round) !== null) {
              finalCounter += 1
            }
          });
        }
        if (game.p8_name! && game.p8_name.length > 0) {
          finalAim += roundsDefault.length
          roundsDefault.forEach(round => {
            if (getRoundValue(game.p8_score, round) !== null) {
              finalCounter += 1
            }
          });
        }
        if (finalCounter === finalAim) {
          setFinal(true)
        } else {
          setFinal(false)
        }
      } else if (game.type === 1) {
        setDartPoints(JSON.parse(game.type_value)[0]);
        const createRounds:number[] = []
        let dartRounds = 0
        if (game.p1_name! && game.p1_name.length > 0) {
          if (Array.isArray(game.p1_score)) {
            if (game.p1_score.length > dartRounds) {
              dartRounds = game.p1_score.length
            }
          }
        }
        if (game.p2_name! && game.p2_name.length > 0) {
          if (Array.isArray(game.p2_score)) {
            if (game.p2_score.length > dartRounds) {
              dartRounds = game.p2_score.length
            }
          }
        }
        if (game.p3_name! && game.p3_name.length > 0) {
          if (Array.isArray(game.p3_score)) {
            if (game.p3_score.length > dartRounds) {
              dartRounds = game.p3_score.length
            }
          }
        }
        if (game.p4_name! && game.p4_name.length > 0) {
          if (Array.isArray(game.p4_score)) {
            if (game.p4_score.length > dartRounds) {
              dartRounds = game.p4_score.length
            }
          }
        }
        if (game.p5_name! && game.p5_name.length > 0) {
          if (Array.isArray(game.p5_score)) {
            if (game.p5_score.length > dartRounds) {
              dartRounds = game.p5_score.length
            }
          }
        }
        if (game.p6_name! && game.p6_name.length > 0) {
          if (Array.isArray(game.p6_score)) {
            if (game.p6_score.length > dartRounds) {
              dartRounds = game.p6_score.length
            }
          }
        }
        if (game.p7_name! && game.p7_name.length > 0) {
          if (Array.isArray(game.p7_score)) {
            if (game.p7_score.length > dartRounds) {
              dartRounds = game.p7_score.length
            }
          }
        }
        if (game.p8_name! && game.p8_name.length > 0) {
          if (Array.isArray(game.p8_score)) {
            if (game.p8_score.length > dartRounds) {
              dartRounds = game.p8_score.length
            }
          }
        }
        for(let i = 1; i <= dartRounds; i++){
          createRounds.unshift(i);
        }
        setRounds(createRounds)
        const r1 = JSON.parse(game.type_value)[0] - (Array.isArray(game.p1_score) && game.p1_score.length > 0 ? game.p1_score.reduce((x, y) => x + y) : 0)
        const r2 = JSON.parse(game.type_value)[0] - (Array.isArray(game.p2_score) && game.p2_score.length > 0 ? game.p2_score.reduce((x, y) => x + y) : 0)
        const r3 = JSON.parse(game.type_value)[0] - (Array.isArray(game.p3_score) && game.p3_score.length > 0 ? game.p3_score.reduce((x, y) => x + y) : 0)
        const r4 = JSON.parse(game.type_value)[0] - (Array.isArray(game.p4_score) && game.p4_score.length > 0 ? game.p4_score.reduce((x, y) => x + y) : 0)
        const r5 = JSON.parse(game.type_value)[0] - (Array.isArray(game.p5_score) && game.p5_score.length > 0 ? game.p5_score.reduce((x, y) => x + y) : 0)
        const r6 = JSON.parse(game.type_value)[0] - (Array.isArray(game.p6_score) && game.p6_score.length > 0 ? game.p6_score.reduce((x, y) => x + y) : 0)
        const r7 = JSON.parse(game.type_value)[0] - (Array.isArray(game.p7_score) && game.p7_score.length > 0 ? game.p7_score.reduce((x, y) => x + y) : 0)
        const r8 = JSON.parse(game.type_value)[0] - (Array.isArray(game.p8_score) && game.p8_score.length > 0 ? game.p8_score.reduce((x, y) => x + y) : 0)
        if ((r1 < 0) || (r2 < 0) || (r3 < 0) || (r4 < 0) || (r5 < 0) || (r6 < 0) || (r7 < 0) || (r8 < 0)) {
          setFinal(false)
          setDartError(true)
        } else if ((r1 === 0) || (r2 === 0) || (r3 === 0) || (r4 === 0) || (r5 === 0) || (r6 === 0) || (r7 === 0) || (r8 === 0)) {
          setFinal(true)
          setDartError(false)
        } else {
          setFinal(false)
          setDartError(false)
        }
      }
    }
  }, [game]);

  useEffect(() => {
    if (toLocalStorage && game!) {
      const lsValue = getLocalStorage()
      if (lsValue!) {
        if (lsValue.games!.filter(elem => elem.id === id).length > 0) {
          //game bereits vorhanden
        } else {
          const player:string[] = [];
          game?.p1_name && game?.p1_name.length > 0 && player.push(game.p1_name);
          game?.p2_name && game?.p2_name.length > 0 && player.push(game.p2_name);
          game?.p3_name && game?.p3_name.length > 0 && player.push(game.p3_name);
          game?.p4_name && game?.p4_name.length > 0 && player.push(game.p4_name);
          game?.p5_name && game?.p5_name.length > 0 && player.push(game.p5_name);
          game?.p6_name && game?.p6_name.length > 0 && player.push(game.p6_name);
          game?.p7_name && game?.p7_name.length > 0 && player.push(game.p7_name);
          game?.p8_name && game?.p8_name.length > 0 && player.push(game.p8_name);
          const newGame:SkeeboardLocalStorageGame = {
            id: id,
            datetime: game!.datetime,
            player: player
          }
          lsValue.games.unshift(newGame)    
          setLocalStorage(lsValue)
        }        
      }
    }
  }, [toLocalStorage]);

  //alle 10 Sekunden ein Select
  useEffect(() => {
    const interval = setInterval(() => {
      if (!openDialogScore) {
        getGame(false);
      }
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const getGame = (first: boolean) => {
    if (id! && id.length > 0 && id !== "undefined") {
      const bodyFormData = new FormData();
      bodyFormData.append("id", id);
      axios
      .post('/api/zgkzm6c0aqtn.php', bodyFormData)
      .then(response => {
        const newGame:any = response.data;
        newGame.type = Number(newGame.type)
        newGame.machine = Number(newGame.machine)
        newGame.final = Number(newGame.final)
        if (newGame?.p1_score) {
          newGame.p1_score = newGame.p1_score.replace(/\\/g, '');
          newGame.p1_score = JSON.parse(newGame.p1_score);
          newGame.p1_sum = 0;
        }
        if (newGame?.p2_score) {
          newGame.p2_score = newGame.p2_score.replace(/\\/g, '');
          newGame.p2_score = JSON.parse(newGame.p2_score);
          newGame.p2_sum = 0;
        }
        if (newGame?.p3_score) {
          newGame.p3_score = newGame.p3_score.replace(/\\/g, '');
          newGame.p3_score = JSON.parse(newGame.p3_score);
          newGame.p3_sum = 0;
        }
        if (newGame?.p4_score) {
          newGame.p4_score = newGame.p4_score.replace(/\\/g, '');
          newGame.p4_score = JSON.parse(newGame.p4_score);
          newGame.p4_sum = 0;
        }
        if (newGame?.p5_score) {
          newGame.p5_score = newGame.p5_score.replace(/\\/g, '');
          newGame.p5_score = JSON.parse(newGame.p5_score);
          newGame.p5_sum = 0;
        }
        if (newGame?.p6_score) {
          newGame.p6_score = newGame.p6_score.replace(/\\/g, '');
          newGame.p6_score = JSON.parse(newGame.p6_score);
          newGame.p6_sum = 0;
        }
        if (newGame?.p7_score) {
          newGame.p7_score = newGame.p7_score.replace(/\\/g, '');
          newGame.p7_score = JSON.parse(newGame.p7_score);
          newGame.p7_sum = 0;
        }
        if (newGame?.p8_score) {
          newGame.p8_score = newGame.p8_score.replace(/\\/g, '');
          newGame.p8_score = JSON.parse(newGame.p8_score);
          newGame.p8_sum = 0;
        }
        //Summe bilden
        createSumsAverage(newGame);
        setGame(response.data);
        if (first && playerButton === undefined && !newGame.final) {
          const newPlayerButton: SkeeboardPlayerButton[] = []
          if (newGame.p1_name! && newGame.p1_name.length > 0) {
            newPlayerButton.push({player:1,name:newGame.p1_name,show:true})
          }
          if (newGame.p2_name! && newGame.p2_name.length > 0) {
            newPlayerButton.push({player:2,name:newGame.p2_name,show:true})
          }
          if (newGame.p3_name! && newGame.p3_name.length > 0) {
            newPlayerButton.push({player:3,name:newGame.p3_name,show:true})
          }
          if (newGame.p4_name! && newGame.p4_name.length > 0) {
            newPlayerButton.push({player:4,name:newGame.p4_name,show:true})
          }
          if (newGame.p5_name! && newGame.p5_name.length > 0) {
            newPlayerButton.push({player:5,name:newGame.p5_name,show:true})
          }
          if (newGame.p6_name! && newGame.p6_name.length > 0) {
            newPlayerButton.push({player:6,name:newGame.p6_name,show:true})
          }
          if (newGame.p7_name! && newGame.p7_name.length > 0) {
            newPlayerButton.push({player:7,name:newGame.p7_name,show:true})
          }
          if (newGame.p8_name! && newGame.p8_name.length > 0) {
            newPlayerButton.push({player:8,name:newGame.p8_name,show:true})
          }
          setPlayerButton(newPlayerButton)
        }
        setToLocalStorage(true);

      })
      .catch(function(error) {
        console.log(error)
      });
    }
  }

  const createSumsAverage = (paramGame:SkeeboardGame) => {
    let s1:number, s2:number, s3:number, s4:number, s5:number, s6:number, s7:number, s8:number;
    s1 = s2 = s3 = s4 = s5 =s6 = s7 =s8 = 0;
    let a1:number, a2:number, a3:number, a4:number, a5:number, a6:number, a7:number, a8:number;
    a1 = a2 = a3 = a4 = a5 =a6 = a7 =a8 = 0;
    roundsDefault.map((round) => {
      if (paramGame?.p1_score) {
        const score = Number(getRoundValue(paramGame!.p1_score, round));
        if (score!) {
          s1 += score;
          a1++;
        } else {
          s1 += 0;
        }
      }
      if (paramGame?.p2_score) {
        const score = Number(getRoundValue(paramGame!.p2_score, round));
        if (score!) {
          s2 += score;
          a2++;
        } else {
          s2 += 0
        }
      }
      if (paramGame?.p3_score) {
        const score = Number(getRoundValue(paramGame!.p3_score, round));
        if (score!) {
          s3 += score;
          a3++;
        } else {
          s3 += 0
        }
      }
      if (paramGame?.p4_score) {
        const score = Number(getRoundValue(paramGame!.p4_score, round));
        if (score!) {
          s4 += score;
          a4++;
        } else {
          s4 += 0
        }
      }
      if (paramGame?.p5_score) {
        const score = Number(getRoundValue(paramGame!.p5_score, round));
        if (score!) {
          s5 += score;
          a5++;
        } else {
          s5 += 0
        }
      }
      if (paramGame?.p6_score) {
        const score = Number(getRoundValue(paramGame!.p6_score, round));
        if (score!) {
          s6 += score;
          a6++;
        } else {
          s6 += 0
        }
      }
      if (paramGame?.p7_score) {
        const score = Number(getRoundValue(paramGame!.p7_score, round));
        if (score!) {
          s7 += score;
          a7++;
        } else {
          s7 += 0
        }
      }
      if (paramGame?.p8_score) {
        const score = Number(getRoundValue(paramGame!.p8_score, round));
        if (score!) {
          s8 += score;
          a8++;
        } else {
          s8 += 0
        }
      }
    })
    setSumAve({
      sum1: s1, 
      sum2: s2, 
      sum3: s3, 
      sum4: s4, 
      sum5: s5, 
      sum6: s6, 
      sum7: s7, 
      sum8: s8, 
      ave1: a1>0?Number((s1 / a1).toFixed(1)):0, 
      ave2: a2>0?Number((s2 / a2).toFixed(1)):0, 
      ave3: a3>0?Number((s3 / a3).toFixed(1)):0, 
      ave4: a4>0?Number((s4 / a4).toFixed(1)):0, 
      ave5: a5>0?Number((s5 / a5).toFixed(1)):0, 
      ave6: a6>0?Number((s6 / a6).toFixed(1)):0, 
      ave7: a7>0?Number((s7 / a7).toFixed(1)):0, 
      ave8: a8>0?Number((s8 / a8).toFixed(1)):0})
  };

  const updateGame = async (player:number,score:string) => {
    if (game!) {
      const bodyFormData = new FormData();
      bodyFormData.append("id", game.id);
      bodyFormData.append("p_number", String(player));
      bodyFormData.append("p_score", score);
      axios
      .post('/api/zqk2n98wtv6t.php', bodyFormData)
      .then(()=>{
        setRefreshBoard(true);
      })
      .catch(function(error) {
        console.log(error);

      });    
    }
  };

  const getRoundValue = (player_score:any, round:number):(number | null) => {
    let key: keyof SkeeboardScore;
    if (instanceOfSkeeboardScore(player_score)) {
      for (key in player_score) {
        if (key === 'r'+String(round)) {
          if (player_score[key] === null) {
            return null;
          }
          // setFinalCounter(finalCounter+1)
          return player_score[key];
        }
      }
    } else {
      if (player_score.length >= round) {
        return player_score[player_score.length - round];
      }
    }
    return null;
  };

  const setRoundValue = (player_score:any, round:number, value:number | null):SkeeboardScore => {
    let key: keyof SkeeboardScore;
    if (instanceOfSkeeboardScore(player_score)) {
      for (key in player_score) {
        if (key === 'r'+String(round)) {
          player_score[key] = value;
          break;
        }
      }
    }
    return player_score;
  };

  const handleShowNames = () => {
    setShowNames(!showNames)
  };

  const finalGame = (id:string) => {
    if (id.length > 0 ) {
      const bodyFormData = new FormData();
      bodyFormData.append("id", id);
      axios
      .post('/api/zd1kyalugn05.php', bodyFormData)
      .then(response => {
        console.log(response.data)
      })
      .catch(function(error) {
        console.log(error)
      });    
    }
  };


  const checkShowPlayerButton = (player:number) => {
    if (playerButton!) {
      const index = playerButton.findIndex(object => {
        return object.player === player;
      });
      if (index !== -1) {
        return playerButton[index].show;
      }
      return false;
    }
    return false;
  };

  const handleShowPlayerButton = (player:number) => {
    if (playerButton!) {
      const newPlayerButton: SkeeboardPlayerButton[] = [...playerButton]
      const index = newPlayerButton.findIndex(object => {
        return object.player === player;
      });
      if (index !== -1) {
        newPlayerButton[index].show = !newPlayerButton[index].show;
      }
      setPlayerButton(newPlayerButton)
    }
  };

  const updateScore = (player:number,round:number,value:number | null) => {
    const newGame = game;
    let doUpdate = false;
    if (newGame!) {
      switch(player) {
        case 1:
          newGame.p1_score = setRoundValue(newGame.p1_score,round,value);
          doUpdate = true;
          break;
        case 2:
          newGame.p2_score = setRoundValue(newGame.p2_score,round,value);
          doUpdate = true;
          break;
        case 3:
          newGame.p3_score = setRoundValue(newGame.p3_score,round,value);
          doUpdate = true;
          break;
        case 4:
          newGame.p4_score = setRoundValue(newGame.p4_score,round,value);
          doUpdate = true;
          break;
        case 5:
          newGame.p5_score = setRoundValue(newGame.p5_score,round,value);
          doUpdate = true;
          break;
        case 6:
          newGame.p6_score = setRoundValue(newGame.p6_score,round,value);
          doUpdate = true;
          break;
        case 7:
          newGame.p7_score = setRoundValue(newGame.p7_score,round,value);
          doUpdate = true;
          break;
        case 8:
          newGame.p8_score = setRoundValue(newGame.p8_score,round,value);
          doUpdate = true;
          break;
      }
      if (doUpdate) {
        createSumsAverage(newGame);
        setGame(newGame);
        switch(player) {
          case 1:
            updateGame(player,JSON.stringify(newGame.p1_score));
            break;
          case 2:
            updateGame(player,JSON.stringify(newGame.p2_score));
            break;
          case 3:
            updateGame(player,JSON.stringify(newGame.p3_score));
            break;
          case 4:
            updateGame(player,JSON.stringify(newGame.p4_score));
            break;
          case 5:
            updateGame(player,JSON.stringify(newGame.p5_score));
            break;
          case 6:
            updateGame(player,JSON.stringify(newGame.p6_score));
            break;
          case 7:
            updateGame(player,JSON.stringify(newGame.p7_score));
            break;
          case 8:
            updateGame(player,JSON.stringify(newGame.p8_score));
            break;
        }
      }
    }
  };

  const handleDialogScoreOpen = (player: number, round: number, value: number | null) => {
    const newDialog:SkeeboardDialog = {
      player: player,
      round: round,
      value: value!,
      showClear: true      
    }
    setDialog(newDialog)
    setOpenDialogScore(true);
  };

  const updateScoreDart = (player:number,value:number) => {
    const newGame = JSON.parse(JSON.stringify(game));
    let doUpdate = false;
    if (newGame!) {
      switch(player) {
        case 1:
          if (Array.isArray(newGame.p1_score)) {
            if (value >= 0) {
              newGame.p1_score.unshift(value);
            } else {
              newGame.p1_score.shift();
            }            
          }
          doUpdate = true;
          break;
        case 2:
          if (Array.isArray(newGame.p2_score)) {
            if (value >= 0) {
              newGame.p2_score.unshift(value);
            } else {
              newGame.p2_score.shift();
            }            
          }
          doUpdate = true;
          break;
        case 3:
          if (Array.isArray(newGame.p3_score)) {
            if (value >= 0) {
              newGame.p3_score.unshift(value);
            } else {
              newGame.p3_score.shift();
            }            
          }
          doUpdate = true;
          break;
        case 4:
          if (Array.isArray(newGame.p4_score)) {
            if (value >= 0) {
              newGame.p4_score.unshift(value);
            } else {
              newGame.p4_score.shift();
            }            
          }
          doUpdate = true;
          break;
        case 5:
          if (Array.isArray(newGame.p5_score)) {
            if (value >= 0) {
              newGame.p5_score.unshift(value);
            } else {
              newGame.p5_score.shift();
            }            
          }
          doUpdate = true;
          break;
        case 6:
          if (Array.isArray(newGame.p6_score)) {
            if (value >= 0) {
              newGame.p6_score.unshift(value);
            } else {
              newGame.p6_score.shift();
            }            
          }
          doUpdate = true;
          break;
        case 7:
          if (Array.isArray(newGame.p7_score)) {
            if (value >= 0) {
              newGame.p7_score.unshift(value);
            } else {
              newGame.p7_score.shift();
            }            
          }
          doUpdate = true;
          break;
        case 8:
          if (Array.isArray(newGame.p8_score)) {
            if (value >= 0) {
              newGame.p8_score.unshift(value);
            } else {
              newGame.p8_score.shift();
            }            
          }
          doUpdate = true;
          break;
      }
      if (doUpdate) {
        setGame(newGame);
        switch(player) {
          case 1:
            updateGame(player,JSON.stringify(newGame.p1_score));
            break;
          case 2:
            updateGame(player,JSON.stringify(newGame.p2_score));
            break;
          case 3:
            updateGame(player,JSON.stringify(newGame.p3_score));
            break;
          case 4:
            updateGame(player,JSON.stringify(newGame.p4_score));
            break;
          case 5:
            updateGame(player,JSON.stringify(newGame.p5_score));
            break;
          case 6:
            updateGame(player,JSON.stringify(newGame.p6_score));
            break;
          case 7:
            updateGame(player,JSON.stringify(newGame.p7_score));
            break;
          case 8:
            updateGame(player,JSON.stringify(newGame.p8_score));
            break;
        }
      }
    }
  };

  const handleDialogScoreDartOpen = (player: number) => {
    const newDialog:SkeeboardDialog = {
      player: player,
      round: 0,
      value: null,
      showClear: false      
    }
    setDialog(newDialog)
    setOpenDialogScore(true);
  };

  const handleDialogScoreClose = (save: boolean, value: number | null, dialog:SkeeboardDialog) => {
    setOpenDialogScore(false);
    if (save) {
      if (dialog.round > 0) {
        updateScore(dialog.player, dialog.round, value)
      } else {
        updateScoreDart(dialog.player, Number(value))
      }  
    }
  };

  const handleDialogQROpen = () => {
    setOpenDialogQR(true);
  };

  const handleDialogQRClose = () => {
    setOpenDialogQR(false);
  };

  
  //window size listener
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const TerminalTableRow = styled(TableRow)(() => ({
    '&': {
      backgroundColor: dartError?props.darkmode!?'#470000':'#ffc4c4':props.darkmode!?'#3e3e3e':'#eaeaea',
    },
  }));
  const TerminalTableCell = styled(TableCell)(() => ({
    '&': {
      backgroundColor: dartError?props.darkmode!?'#470000':'#ffc4c4':props.darkmode!?'#3e3e3e':'#eaeaea',
    },
  }));

  return (
    <PageLayout 
      name={"game"} 
      darkmode={props.darkmode!}
      handleDarkmode={props.handleDarkmode!}
      english={props.english!}
      handleEnglish={props.handleEnglish!}
      gameHandleShowNames={handleShowNames} 
      gameShowNames={showNames}
      gameHandleDialogQROpen={handleDialogQROpen}
      gameHandleShowPlayerButton={handleShowPlayerButton}
      gamePlayerButton={playerButton} >
        <Box textAlign='center' sx={{ p: 2 }}>
          <Typography variant="h5" gutterBottom>
            {game!?game!.name:""}
          </Typography>
          {/* <Typography variant="h6" gutterBottom>
            {game!?" "+convertDateTime(game!.datetime,false):""}
          </Typography> */}
        </Box>

        {isMobile && game! && game.type === 0 &&
        <TableContainer component={Paper}>
          <Table aria-label="Punktetabelle">
            <TableHead>
              <TableRow>
                <TableCell>{props.english!?"round":"Runde"}</TableCell>
                {game?.p1_name && game?.p1_name.length > 0 &&
                  <TableCell>{showNames ? game?.p1_name : game?.p1_name.slice(0, 3)}</TableCell>
                }
                {game?.p2_name && game?.p2_name.length > 0 &&
                  <TableCell>{showNames ? game?.p2_name : game?.p2_name.slice(0, 3)}</TableCell>
                }
                {game?.p3_name && game?.p3_name.length > 0 &&
                  <TableCell>{showNames ? game?.p3_name : game?.p3_name.slice(0, 3)}</TableCell>
                }
                {game?.p4_name && game?.p4_name.length > 0 &&
                  <TableCell>{showNames ? game?.p4_name : game?.p4_name.slice(0, 3)}</TableCell>
                }
                {game?.p5_name && game?.p5_name.length > 0 &&
                  <TableCell>{showNames ? game?.p5_name : game?.p5_name.slice(0, 3)}</TableCell>
                }
                {game?.p6_name && game?.p6_name.length > 0 &&
                  <TableCell>{showNames ? game?.p6_name : game?.p6_name.slice(0, 3)}</TableCell>
                }
                {game?.p7_name && game?.p7_name.length > 0 &&
                  <TableCell>{showNames ? game?.p7_name : game?.p7_name.slice(0, 3)}</TableCell>
                }
                {game?.p8_name && game?.p8_name.length > 0 &&
                  <TableCell>{showNames ? game?.p8_name : game?.p8_name.slice(0, 3)}</TableCell>
                }
                {game! && game.final === 0 &&
                <TableCell><div style={{display: 'none' }}>Punktevergabe</div></TableCell>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {rounds.map((round) => (
                <TableRow
                  key={round}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">{round}</TableCell>
                  
                  {game?.p1_name && game?.p1_name.length > 0 &&
                    <TableCell>{getRoundValue(game?.p1_score, round)}</TableCell>
                  }
                  {game?.p2_name && game?.p2_name.length > 0 &&
                    <TableCell>{getRoundValue(game?.p2_score, round)}</TableCell>
                  }
                  {game?.p3_name && game?.p3_name.length > 0 &&
                    <TableCell>{getRoundValue(game?.p3_score, round)}</TableCell>
                  }
                  {game?.p4_name && game?.p4_name.length > 0 &&
                    <TableCell>{getRoundValue(game?.p4_score, round)}</TableCell>
                  }
                  {game?.p5_name && game?.p5_name.length > 0 &&
                    <TableCell>{getRoundValue(game?.p5_score, round)}</TableCell>
                  }
                  {game?.p6_name && game?.p6_name.length > 0 &&
                    <TableCell>{getRoundValue(game?.p6_score, round)}</TableCell>
                  }
                  {game?.p7_name && game?.p7_name.length > 0 &&
                    <TableCell>{getRoundValue(game?.p7_score, round)}</TableCell>
                  }
                  {game?.p8_name && game?.p8_name.length > 0 &&
                    <TableCell>{getRoundValue(game?.p8_score, round)}</TableCell>
                  }
                  {game! && game.final === 0 &&
                  <TableCell>
                    {game?.p1_name && game?.p1_name.length > 0 && checkShowPlayerButton(1) &&
                      <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(1,round,getRoundValue(game?.p1_score, round))}>{showNames ? game?.p1_name : game?.p1_name.slice(0, 3)}</Button>
                    }
                    {game?.p2_name && game?.p2_name.length > 0 && checkShowPlayerButton(2) &&
                      <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(2,round,getRoundValue(game?.p2_score, round))}>{showNames ? game?.p2_name : game?.p2_name.slice(0, 3)}</Button>
                    }
                    {game?.p3_name && game?.p3_name.length > 0 && checkShowPlayerButton(3) &&
                      <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(3,round,getRoundValue(game?.p3_score, round))}>{showNames ? game?.p3_name : game?.p3_name.slice(0, 3)}</Button>
                    }
                    {game?.p4_name && game?.p4_name.length > 0 && checkShowPlayerButton(4) &&
                      <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(4,round,getRoundValue(game?.p4_score, round))}>{showNames ? game?.p4_name : game?.p4_name.slice(0, 3)}</Button>
                    }
                    {game?.p5_name && game?.p5_name.length > 0 && checkShowPlayerButton(5) &&
                      <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(5,round,getRoundValue(game?.p5_score, round))}>{showNames ? game?.p5_name : game?.p5_name.slice(0, 3)}</Button>
                    }
                    {game?.p6_name && game?.p6_name.length > 0 && checkShowPlayerButton(6) &&
                      <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(6,round,getRoundValue(game?.p6_score, round))}>{showNames ? game?.p6_name : game?.p6_name.slice(0, 3)}</Button>
                    }
                    {game?.p7_name && game?.p7_name.length > 0 && checkShowPlayerButton(7) &&
                      <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(7,round,getRoundValue(game?.p7_score, round))}>{showNames ? game?.p7_name : game?.p7_name.slice(0, 3)}</Button>
                    }
                    {game?.p8_name && game?.p8_name.length > 0 && checkShowPlayerButton(8) &&
                      <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(8,round,getRoundValue(game?.p8_score, round))}>{showNames ? game?.p8_name : game?.p8_name.slice(0, 3)}</Button>
                    }
                  </TableCell>
                  }
                </TableRow>
              ))}
              <TableRow>
                  <TerminalTableCell component="th" scope="row">
                    <FunctionsIcon fontSize="small" />
                  </TerminalTableCell>
                  {game?.p1_name && game?.p1_name.length > 0 &&
                    <TerminalTableCell>{sumAve.sum1}</TerminalTableCell>
                  }
                  {game?.p2_name && game?.p2_name.length > 0 &&
                    <TerminalTableCell>{sumAve.sum2}</TerminalTableCell>
                  }
                  {game?.p3_name && game?.p3_name.length > 0 &&
                    <TerminalTableCell>{sumAve.sum3}</TerminalTableCell>
                  }
                  {game?.p4_name && game?.p4_name.length > 0 &&
                    <TerminalTableCell>{sumAve.sum4}</TerminalTableCell>
                  }
                  {game?.p5_name && game?.p5_name.length > 0 &&
                    <TerminalTableCell>{sumAve.sum5}</TerminalTableCell>
                  }
                  {game?.p6_name && game?.p6_name.length > 0 &&
                    <TerminalTableCell>{sumAve.sum6}</TerminalTableCell>
                  }
                  {game?.p7_name && game?.p7_name.length > 0 &&
                    <TerminalTableCell>{sumAve.sum7}</TerminalTableCell>
                  }
                  {game?.p8_name && game?.p8_name.length > 0 &&
                    <TerminalTableCell>{sumAve.sum8}</TerminalTableCell>
                  }
                </TableRow>
                <TableRow>
                  <TerminalTableCell component="th" scope="row">
                    <EqualizerIcon fontSize="small" />
                  </TerminalTableCell>
                  {game?.p1_name && game?.p1_name.length > 0 &&
                    <TerminalTableCell>{sumAve.ave1}</TerminalTableCell>
                  }
                  {game?.p2_name && game?.p2_name.length > 0 &&
                    <TerminalTableCell>{sumAve.ave2}</TerminalTableCell>
                  }
                  {game?.p3_name && game?.p3_name.length > 0 &&
                    <TerminalTableCell>{sumAve.ave3}</TerminalTableCell>
                  }
                  {game?.p4_name && game?.p4_name.length > 0 &&
                    <TerminalTableCell>{sumAve.ave4}</TerminalTableCell>
                  }
                  {game?.p5_name && game?.p5_name.length > 0 &&
                    <TerminalTableCell>{sumAve.ave5}</TerminalTableCell>
                  }
                  {game?.p6_name && game?.p6_name.length > 0 &&
                    <TerminalTableCell>{sumAve.ave6}</TerminalTableCell>
                  }
                  {game?.p7_name && game?.p7_name.length > 0 &&
                    <TerminalTableCell>{sumAve.ave7}</TerminalTableCell>
                  }
                  {game?.p8_name && game?.p8_name.length > 0 &&
                    <TerminalTableCell>{sumAve.ave8}</TerminalTableCell>
                  }
                </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        }

        {!isMobile && game! && game.type === 0 &&
        <TableContainer component={Paper}>
          <Table aria-label="Punktetabelle">
            <TableHead>
              <TableRow>
                <TableCell>{props.english!?"round":"Runde"}</TableCell>

                {rounds.map((round) => (
                  <TableCell component="th" scope="row" key={round}>{round}</TableCell>
                ))}
                <TerminalTableCell><FunctionsIcon fontSize="small" /></TerminalTableCell>
                <TerminalTableCell><EqualizerIcon fontSize="small" /></TerminalTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {game?.p1_name && game?.p1_name.length > 0 &&
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                
                  <TableCell component="th" scope="row">{showNames ? game?.p1_name : game?.p1_name.slice(0, 3)}</TableCell>
                
                  {rounds.map((round) => (
                  <TableCell key={round}>{getRoundValue(game?.p1_score, round)}</TableCell>
                  ))}
                  <TerminalTableCell>{sumAve.sum1}</TerminalTableCell>
                  <TerminalTableCell>{sumAve.ave1}</TerminalTableCell>
                </TableRow>
              }

              {game?.p2_name && game?.p2_name.length > 0 &&
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                
                  <TableCell component="th" scope="row">{showNames ? game?.p2_name : game?.p2_name.slice(0, 3)}</TableCell>
                
                  {rounds.map((round) => (
                  <TableCell key={round}>{getRoundValue(game?.p2_score, round)}</TableCell>
                  ))}
                  <TerminalTableCell>{sumAve.sum2}</TerminalTableCell>
                  <TerminalTableCell>{sumAve.ave2}</TerminalTableCell>
                </TableRow>
              }

              {game?.p3_name && game?.p3_name.length > 0 &&
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                
                  <TableCell component="th" scope="row">{showNames ? game?.p3_name : game?.p3_name.slice(0, 3)}</TableCell>
                
                  {rounds.map((round) => (
                  <TableCell key={round}>{getRoundValue(game?.p3_score, round)}</TableCell>
                  ))}
                  <TerminalTableCell>{sumAve.sum3}</TerminalTableCell>
                  <TerminalTableCell>{sumAve.ave3}</TerminalTableCell>
                </TableRow>
              }

              {game?.p4_name && game?.p4_name.length > 0 &&
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                
                  <TableCell component="th" scope="row">{showNames ? game?.p4_name : game?.p4_name.slice(0, 3)}</TableCell>
                
                  {rounds.map((round) => (
                  <TableCell key={round}>{getRoundValue(game?.p4_score, round)}</TableCell>
                  ))}
                  <TerminalTableCell>{sumAve.sum4}</TerminalTableCell>
                  <TerminalTableCell>{sumAve.ave4}</TerminalTableCell>
                </TableRow>
              }

              {game?.p5_name && game?.p5_name.length > 0 &&
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                
                  <TableCell component="th" scope="row">{showNames ? game?.p5_name : game?.p5_name.slice(0, 3)}</TableCell>
                
                  {rounds.map((round) => (
                  <TableCell key={round}>{getRoundValue(game?.p5_score, round)}</TableCell>
                  ))}
                  <TerminalTableCell>{sumAve.sum5}</TerminalTableCell>
                  <TerminalTableCell>{sumAve.ave5}</TerminalTableCell>
                </TableRow>
              }

              {game?.p6_name && game?.p6_name.length > 0 &&
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                
                  <TableCell component="th" scope="row">{showNames ? game?.p6_name : game?.p6_name.slice(0, 3)}</TableCell>
                
                  {rounds.map((round) => (
                  <TableCell key={round}>{getRoundValue(game?.p6_score, round)}</TableCell>
                  ))}
                  <TerminalTableCell>{sumAve.sum6}</TerminalTableCell>
                  <TerminalTableCell>{sumAve.ave6}</TerminalTableCell>
                </TableRow>
              }

              {game?.p7_name && game?.p7_name.length > 0 &&
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                
                  <TableCell component="th" scope="row">{showNames ? game?.p7_name : game?.p7_name.slice(0, 3)}</TableCell>
                
                  {rounds.map((round) => (
                  <TableCell key={round}>{getRoundValue(game?.p7_score, round)}</TableCell>
                  ))}
                  <TerminalTableCell>{sumAve.sum7}</TerminalTableCell>
                  <TerminalTableCell>{sumAve.ave7}</TerminalTableCell>
                </TableRow>
              }

              {game?.p8_name && game?.p8_name.length > 0 &&
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                
                  <TableCell component="th" scope="row">{showNames ? game?.p8_name : game?.p8_name.slice(0, 3)}</TableCell>
                
                  {rounds.map((round) => (
                  <TableCell key={round}>{getRoundValue(game?.p8_score, round)}</TableCell>
                  ))}
                  <TerminalTableCell>{sumAve.sum8}</TerminalTableCell>
                  <TerminalTableCell>{sumAve.ave8}</TerminalTableCell>
                </TableRow>
              }

              {game! && game.final === 0 &&
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              
                <TableCell></TableCell>
              
                {rounds.map((round) => (
                  <TableCell key={round}>
                  {game?.p1_name && game?.p1_name.length > 0 && checkShowPlayerButton(1) &&
                    <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(1,round,getRoundValue(game?.p1_score, round))}>{showNames ? game?.p1_name : game?.p1_name.slice(0, 3)}</Button>
                  }
                  {game?.p2_name && game?.p2_name.length > 0 && checkShowPlayerButton(2) &&
                    <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(2,round,getRoundValue(game?.p2_score, round))}>{showNames ? game?.p2_name : game?.p2_name.slice(0, 3)}</Button>
                  }
                  {game?.p3_name && game?.p3_name.length > 0 && checkShowPlayerButton(3) &&
                    <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(3,round,getRoundValue(game?.p3_score, round))}>{showNames ? game?.p3_name : game?.p3_name.slice(0, 3)}</Button>
                  }
                  {game?.p4_name && game?.p4_name.length > 0 && checkShowPlayerButton(4) &&
                    <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(4,round,getRoundValue(game?.p4_score, round))}>{showNames ? game?.p4_name : game?.p4_name.slice(0, 3)}</Button>
                  }
                  {game?.p5_name && game?.p5_name.length > 0 && checkShowPlayerButton(5) &&
                    <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(5,round,getRoundValue(game?.p5_score, round))}>{showNames ? game?.p5_name : game?.p5_name.slice(0, 3)}</Button>
                  }
                  {game?.p6_name && game?.p6_name.length > 0 && checkShowPlayerButton(6) &&
                    <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(6,round,getRoundValue(game?.p6_score, round))}>{showNames ? game?.p6_name : game?.p6_name.slice(0, 3)}</Button>
                  }
                  {game?.p7_name && game?.p7_name.length > 0 && checkShowPlayerButton(7) &&
                    <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(7,round,getRoundValue(game?.p7_score, round))}>{showNames ? game?.p7_name : game?.p7_name.slice(0, 3)}</Button>
                  }
                  {game?.p8_name && game?.p8_name.length > 0 && checkShowPlayerButton(8) &&
                    <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(8,round,getRoundValue(game?.p8_score, round))}>{showNames ? game?.p8_name : game?.p8_name.slice(0, 3)}</Button>
                  }
                </TableCell>
                ))}
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
        }

        {/* VARIANTE 1 - DARTS */}

        {isMobile && game! && game.type === 1 &&
        <TableContainer component={Paper}>
          <Table aria-label="Punktetabelle">
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={
                  1 + 
                  (game?.p1_name && game?.p1_name.length > 0 ? 1 : 0) +
                  (game?.p2_name && game?.p2_name.length > 0 ? 1 : 0) +
                  (game?.p3_name && game?.p3_name.length > 0 ? 1 : 0) +
                  (game?.p4_name && game?.p4_name.length > 0 ? 1 : 0) +
                  (game?.p5_name && game?.p5_name.length > 0 ? 1 : 0) +
                  (game?.p6_name && game?.p6_name.length > 0 ? 1 : 0) +
                  (game?.p7_name && game?.p7_name.length > 0 ? 1 : 0) +
                  (game?.p8_name && game?.p8_name.length > 0 ? 1 : 0)
                  }>
                  <Typography variant="h6">
                    {dartPoints}
                  </Typography>
              </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Spieler</TableCell>
                {game?.p1_name && game?.p1_name.length > 0 &&
                  <TableCell align="center">{showNames ? game?.p1_name : game?.p1_name.slice(0, 3)}</TableCell>
                }
                {game?.p2_name && game?.p2_name.length > 0 &&
                  <TableCell align="center">{showNames ? game?.p2_name : game?.p2_name.slice(0, 3)}</TableCell>
                }
                {game?.p3_name && game?.p3_name.length > 0 &&
                  <TableCell align="center">{showNames ? game?.p3_name : game?.p3_name.slice(0, 3)}</TableCell>
                }
                {game?.p4_name && game?.p4_name.length > 0 &&
                  <TableCell align="center">{showNames ? game?.p4_name : game?.p4_name.slice(0, 3)}</TableCell>
                }
                {game?.p5_name && game?.p5_name.length > 0 &&
                  <TableCell align="center">{showNames ? game?.p5_name : game?.p5_name.slice(0, 3)}</TableCell>
                }
                {game?.p6_name && game?.p6_name.length > 0 &&
                  <TableCell align="center">{showNames ? game?.p6_name : game?.p6_name.slice(0, 3)}</TableCell>
                }
                {game?.p7_name && game?.p7_name.length > 0 &&
                  <TableCell align="center">{showNames ? game?.p7_name : game?.p7_name.slice(0, 3)}</TableCell>
                }
                {game?.p8_name && game?.p8_name.length > 0 &&
                  <TableCell align="center">{showNames ? game?.p8_name : game?.p8_name.slice(0, 3)}</TableCell>
                }
              </TableRow>

              {game! && game.final === 0 &&
              <TableRow>
                <TableCell></TableCell>
                {game?.p1_name && game?.p1_name.length > 0 && <TableCell align="center"> 
                  {checkShowPlayerButton(1) && <>
                    {!dartError && <IconButton aria-label="Neuer Punktestand" onClick={()=>handleDialogScoreDartOpen(1)}><AddBoxIcon color="secondary"/></IconButton>}
                    <IconButton aria-label="letzen Punktestand löschen" onClick={()=>updateScoreDart(1, -1)}><IndeterminateCheckBoxIcon color="secondary"/></IconButton>
                  </>}
                </TableCell>
                }
                {game?.p2_name && game?.p2_name.length > 0 && <TableCell align="center"> 
                  {checkShowPlayerButton(2) && <>
                    {!dartError && <IconButton aria-label="Neuer Punktestand" onClick={()=>handleDialogScoreDartOpen(2)}><AddBoxIcon color="secondary"/></IconButton>}
                    <IconButton aria-label="letzen Punktestand löschen" onClick={()=>updateScoreDart(2, -1)}><IndeterminateCheckBoxIcon color="secondary"/></IconButton>
                  </>}
                </TableCell>
                }
                {game?.p3_name && game?.p3_name.length > 0 && <TableCell align="center"> 
                  {checkShowPlayerButton(3) && <>
                    {!dartError && <IconButton aria-label="Neuer Punktestand" onClick={()=>handleDialogScoreDartOpen(3)}><AddBoxIcon color="secondary"/></IconButton>}
                    <IconButton aria-label="letzen Punktestand löschen" onClick={()=>updateScoreDart(3, -1)}><IndeterminateCheckBoxIcon color="secondary"/></IconButton>
                  </>}
                </TableCell>
                }
                {game?.p4_name && game?.p4_name.length > 0 && <TableCell align="center"> 
                  {checkShowPlayerButton(4) && <>
                    {!dartError && <IconButton aria-label="Neuer Punktestand" onClick={()=>handleDialogScoreDartOpen(4)}><AddBoxIcon color="secondary"/></IconButton>}
                    <IconButton aria-label="letzen Punktestand löschen" onClick={()=>updateScoreDart(4, -1)}><IndeterminateCheckBoxIcon color="secondary"/></IconButton>
                  </>}
                </TableCell>
                }
                {game?.p5_name && game?.p5_name.length > 0 && <TableCell align="center"> 
                  {checkShowPlayerButton(5) && <>
                    {!dartError && <IconButton aria-label="Neuer Punktestand" onClick={()=>handleDialogScoreDartOpen(5)}><AddBoxIcon color="secondary"/></IconButton>}
                    <IconButton aria-label="letzen Punktestand löschen" onClick={()=>updateScoreDart(5, -1)}><IndeterminateCheckBoxIcon color="secondary"/></IconButton>
                  </>}
                </TableCell>
                }
                {game?.p6_name && game?.p6_name.length > 0 && <TableCell align="center"> 
                  {checkShowPlayerButton(6) && <>
                    {!dartError && <IconButton aria-label="Neuer Punktestand" onClick={()=>handleDialogScoreDartOpen(6)}><AddBoxIcon color="secondary"/></IconButton>}
                    <IconButton aria-label="letzen Punktestand löschen" onClick={()=>updateScoreDart(6, -1)}><IndeterminateCheckBoxIcon color="secondary"/></IconButton>
                  </>}
                </TableCell>
                }
                {game?.p7_name && game?.p7_name.length > 0 && <TableCell align="center"> 
                  {checkShowPlayerButton(7) && <>
                    {!dartError && <IconButton aria-label="Neuer Punktestand" onClick={()=>handleDialogScoreDartOpen(7)}><AddBoxIcon color="secondary"/></IconButton>}
                    <IconButton aria-label="letzen Punktestand löschen" onClick={()=>updateScoreDart(7, -1)}><IndeterminateCheckBoxIcon color="secondary"/></IconButton>
                  </>}
                </TableCell>
                }
                {game?.p8_name && game?.p8_name.length > 0 && <TableCell align="center"> 
                  {checkShowPlayerButton(8) && <>
                    {!dartError && <IconButton aria-label="Neuer Punktestand" onClick={()=>handleDialogScoreDartOpen(8)}><AddBoxIcon color="secondary"/></IconButton>}
                    <IconButton aria-label="letzen Punktestand löschen" onClick={()=>updateScoreDart(8, -1)}><IndeterminateCheckBoxIcon color="secondary"/></IconButton>
                  </>}
                </TableCell>
                }
              </TableRow>
              }
            </TableHead>
            <TableBody>
              <TerminalTableRow>
                <TerminalTableCell>Restwert</TerminalTableCell>
                {game?.p1_name && game?.p1_name.length > 0 &&
                  <TerminalTableCell align="center">
                    {dartPoints - (Array.isArray(game.p1_score) && game.p1_score.length > 0 ? game.p1_score.reduce((x, y) => x + y) : 0)}
                  </TerminalTableCell>
                }
                {game?.p2_name && game?.p2_name.length > 0 &&
                  <TerminalTableCell align="center">
                    {dartPoints - (Array.isArray(game.p2_score) && game.p2_score.length > 0 ? game.p2_score.reduce((x, y) => x + y) : 0)}
                  </TerminalTableCell>
                }
                {game?.p3_name && game?.p3_name.length > 0 &&
                  <TerminalTableCell align="center">
                    {dartPoints - (Array.isArray(game.p3_score) && game.p3_score.length > 0 ? game.p3_score.reduce((x, y) => x + y) : 0)}
                  </TerminalTableCell>
                }
                {game?.p4_name && game?.p4_name.length > 0 &&
                  <TerminalTableCell align="center">
                    {dartPoints - (Array.isArray(game.p4_score) && game.p4_score.length > 0 ? game.p4_score.reduce((x, y) => x + y) : 0)}
                  </TerminalTableCell>
                }
                {game?.p5_name && game?.p5_name.length > 0 &&
                  <TerminalTableCell align="center">
                    {dartPoints - (Array.isArray(game.p5_score) && game.p5_score.length > 0 ? game.p5_score.reduce((x, y) => x + y) : 0)}
                  </TerminalTableCell>
                }
                {game?.p6_name && game?.p6_name.length > 0 &&
                  <TerminalTableCell align="center">
                    {dartPoints - (Array.isArray(game.p6_score) && game.p6_score.length > 0 ? game.p6_score.reduce((x, y) => x + y) : 0)}
                  </TerminalTableCell>
                }
                {game?.p7_name && game?.p7_name.length > 0 &&
                  <TerminalTableCell align="center">
                    {dartPoints - (Array.isArray(game.p7_score) && game.p7_score.length > 0 ? game.p7_score.reduce((x, y) => x + y) : 0)}
                  </TerminalTableCell>
                }
                {game?.p8_name && game?.p8_name.length > 0 &&
                  <TerminalTableCell align="center">
                    {dartPoints - (Array.isArray(game.p8_score) && game.p8_score.length > 0 ? game.p8_score.reduce((x, y) => x + y) : 0)}
                  </TerminalTableCell>
                }
              </TerminalTableRow>

              {rounds.map((round) => (
                <TableRow
                  key={round}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">{props.english!?"round":"Runde"} {round}</TableCell>
                  
                  {game?.p1_name && game?.p1_name.length > 0 &&
                    <TableCell align="center">{getRoundValue(game?.p1_score, round)}</TableCell>
                  }
                  {game?.p2_name && game?.p2_name.length > 0 &&
                    <TableCell align="center">{getRoundValue(game?.p2_score, round)}</TableCell>
                  }
                  {game?.p3_name && game?.p3_name.length > 0 &&
                    <TableCell align="center">{getRoundValue(game?.p3_score, round)}</TableCell>
                  }
                  {game?.p4_name && game?.p4_name.length > 0 &&
                    <TableCell align="center">{getRoundValue(game?.p4_score, round)}</TableCell>
                  }
                  {game?.p5_name && game?.p5_name.length > 0 &&
                    <TableCell align="center">{getRoundValue(game?.p5_score, round)}</TableCell>
                  }
                  {game?.p6_name && game?.p6_name.length > 0 &&
                    <TableCell align="center">{getRoundValue(game?.p6_score, round)}</TableCell>
                  }
                  {game?.p7_name && game?.p7_name.length > 0 &&
                    <TableCell align="center">{getRoundValue(game?.p7_score, round)}</TableCell>
                  }
                  {game?.p8_name && game?.p8_name.length > 0 &&
                    <TableCell align="center">{getRoundValue(game?.p8_score, round)}</TableCell>
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        }

        {!isMobile && game! && game.type === 1 &&
        <TableContainer component={Paper}>
          <Table aria-label="Punktetabelle">
            <TableHead>
              <TableRow>

                <TableCell align="center">
                  <Typography variant="h6">
                    {dartPoints}
                  </Typography>
                </TableCell>
                {game! && game.final === 0 &&
                <TableCell></TableCell> 
                }
                <TableCell align="center">Restwert</TableCell>
                {rounds.map((round) => (
                  <TableCell key={round} component="th" scope="row">{props.english!?"round":"Runde"} {round}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>

              {game?.p1_name && game?.p1_name.length > 0 &&
              <TableRow>
                <TableCell align="center">{showNames ? game?.p1_name : game?.p1_name.slice(0, 3)}</TableCell>
                {game! && game.final === 0 &&
                <TableCell>
                  {checkShowPlayerButton(1) && <>
                      {!dartError && <IconButton aria-label="Neuer Punktestand" onClick={()=>handleDialogScoreDartOpen(1)}><AddBoxIcon color="secondary"/></IconButton>}
                      <IconButton aria-label="letzen Punktestand löschen" onClick={()=>updateScoreDart(1, -1)}><IndeterminateCheckBoxIcon color="secondary"/></IconButton>
                    </>}
                </TableCell>
                }
                <TerminalTableCell align="center">
                  {dartPoints - (Array.isArray(game.p1_score) && game.p1_score.length > 0 ? game.p1_score.reduce((x, y) => x + y) : 0)}
                </TerminalTableCell>
                {rounds.map((round) => (
                <TableCell key={round}>
                  {getRoundValue(game?.p1_score, round)}
                </TableCell>
                  ))}
              </TableRow>
              }

              {game?.p2_name && game?.p2_name.length > 0 &&
              <TableRow>
                <TableCell align="center">{showNames ? game?.p2_name : game?.p2_name.slice(0, 3)}</TableCell>
                {game! && game.final === 0 &&
                <TableCell>
                  {checkShowPlayerButton(2) && <>
                      {!dartError && <IconButton aria-label="Neuer Punktestand" onClick={()=>handleDialogScoreDartOpen(2)}><AddBoxIcon color="secondary"/></IconButton>}
                      <IconButton aria-label="letzen Punktestand löschen" onClick={()=>updateScoreDart(2, -1)}><IndeterminateCheckBoxIcon color="secondary"/></IconButton>
                    </>}
                </TableCell>
                }
                <TerminalTableCell align="center">
                  {dartPoints - (Array.isArray(game.p2_score) && game.p2_score.length > 0 ? game.p2_score.reduce((x, y) => x + y) : 0)}
                </TerminalTableCell>
                {rounds.map((round) => (
                <TableCell key={round}>
                  {getRoundValue(game?.p2_score, round)}
                </TableCell>
                  ))}
              </TableRow>
              }

              {game?.p3_name && game?.p3_name.length > 0 &&
              <TableRow>
                <TableCell align="center">{showNames ? game?.p3_name : game?.p3_name.slice(0, 3)}</TableCell>
                {game! && game.final === 0 &&
                <TableCell>
                  {checkShowPlayerButton(3) && <>
                      {!dartError && <IconButton aria-label="Neuer Punktestand" onClick={()=>handleDialogScoreDartOpen(3)}><AddBoxIcon color="secondary"/></IconButton>}
                      <IconButton aria-label="letzen Punktestand löschen" onClick={()=>updateScoreDart(3, -1)}><IndeterminateCheckBoxIcon color="secondary"/></IconButton>
                    </>}
                </TableCell>
                }
                <TerminalTableCell align="center">
                  {dartPoints - (Array.isArray(game.p3_score) && game.p3_score.length > 0 ? game.p3_score.reduce((x, y) => x + y) : 0)}
                </TerminalTableCell>
                {rounds.map((round) => (
                <TableCell key={round}>
                  {getRoundValue(game?.p3_score, round)}
                </TableCell>
                  ))}
              </TableRow>
              }

              {game?.p4_name && game?.p4_name.length > 0 &&
              <TableRow>
                <TableCell align="center">{showNames ? game?.p4_name : game?.p4_name.slice(0, 3)}</TableCell>
                {game! && game.final === 0 &&
                <TableCell>
                  {checkShowPlayerButton(4) && <>
                      {!dartError && <IconButton aria-label="Neuer Punktestand" onClick={()=>handleDialogScoreDartOpen(4)}><AddBoxIcon color="secondary"/></IconButton>}
                      <IconButton aria-label="letzen Punktestand löschen" onClick={()=>updateScoreDart(4, -1)}><IndeterminateCheckBoxIcon color="secondary"/></IconButton>
                    </>}
                </TableCell>
                }
                <TerminalTableCell align="center">
                  {dartPoints - (Array.isArray(game.p4_score) && game.p4_score.length > 0 ? game.p4_score.reduce((x, y) => x + y) : 0)}
                </TerminalTableCell>
                {rounds.map((round) => (
                <TableCell key={round}>
                  {getRoundValue(game?.p4_score, round)}
                </TableCell>
                  ))}
              </TableRow>
              }

              {game?.p5_name && game?.p5_name.length > 0 &&
              <TableRow>
                <TableCell align="center">{showNames ? game?.p5_name : game?.p5_name.slice(0, 3)}</TableCell>
                {game! && game.final === 0 &&
                <TableCell>
                  {checkShowPlayerButton(5) && <>
                      {!dartError && <IconButton aria-label="Neuer Punktestand" onClick={()=>handleDialogScoreDartOpen(5)}><AddBoxIcon color="secondary"/></IconButton>}
                      <IconButton aria-label="letzen Punktestand löschen" onClick={()=>updateScoreDart(5, -1)}><IndeterminateCheckBoxIcon color="secondary"/></IconButton>
                    </>}
                </TableCell>
                }
                <TerminalTableCell align="center">
                  {dartPoints - (Array.isArray(game.p5_score) && game.p5_score.length > 0 ? game.p5_score.reduce((x, y) => x + y) : 0)}
                </TerminalTableCell>
                {rounds.map((round) => (
                <TableCell key={round}>
                  {getRoundValue(game?.p5_score, round)}
                </TableCell>
                  ))}
              </TableRow>
              }

              {game?.p6_name && game?.p6_name.length > 0 &&
              <TableRow>
                <TableCell align="center">{showNames ? game?.p6_name : game?.p6_name.slice(0, 3)}</TableCell>
                {game! && game.final === 0 &&
                <TableCell>
                  {checkShowPlayerButton(6) && <>
                      {!dartError && <IconButton aria-label="Neuer Punktestand" onClick={()=>handleDialogScoreDartOpen(6)}><AddBoxIcon color="secondary"/></IconButton>}
                      <IconButton aria-label="letzen Punktestand löschen" onClick={()=>updateScoreDart(6, -1)}><IndeterminateCheckBoxIcon color="secondary"/></IconButton>
                    </>}
                </TableCell>
                }
                <TerminalTableCell align="center">
                  {dartPoints - (Array.isArray(game.p6_score) && game.p6_score.length > 0 ? game.p6_score.reduce((x, y) => x + y) : 0)}
                </TerminalTableCell>
                {rounds.map((round) => (
                <TableCell key={round}>
                  {getRoundValue(game?.p6_score, round)}
                </TableCell>
                  ))}
              </TableRow>
              }

              {game?.p7_name && game?.p7_name.length > 0 &&
              <TableRow>
                <TableCell align="center">{showNames ? game?.p7_name : game?.p7_name.slice(0, 3)}</TableCell>
                {game! && game.final === 0 &&
                <TableCell>
                  {checkShowPlayerButton(7) && <>
                      {!dartError && <IconButton aria-label="Neuer Punktestand" onClick={()=>handleDialogScoreDartOpen(7)}><AddBoxIcon color="secondary"/></IconButton>}
                      <IconButton aria-label="letzen Punktestand löschen" onClick={()=>updateScoreDart(7, -1)}><IndeterminateCheckBoxIcon color="secondary"/></IconButton>
                    </>}
                </TableCell>
                }
                <TerminalTableCell align="center">
                  {dartPoints - (Array.isArray(game.p7_score) && game.p7_score.length > 0 ? game.p7_score.reduce((x, y) => x + y) : 0)}
                </TerminalTableCell>
                {rounds.map((round) => (
                <TableCell key={round}>
                  {getRoundValue(game?.p7_score, round)}
                </TableCell>
                  ))}
              </TableRow>
              }

              {game?.p8_name && game?.p8_name.length > 0 &&
              <TableRow>
                <TableCell align="center">{showNames ? game?.p8_name : game?.p8_name.slice(0, 3)}</TableCell>
                {game! && game.final === 0 &&
                <TableCell>
                  {checkShowPlayerButton(8) && <>
                      {!dartError && <IconButton aria-label="Neuer Punktestand" onClick={()=>handleDialogScoreDartOpen(8)}><AddBoxIcon color="secondary"/></IconButton>}
                      <IconButton aria-label="letzen Punktestand löschen" onClick={()=>updateScoreDart(8, -1)}><IndeterminateCheckBoxIcon color="secondary"/></IconButton>
                    </>}
                </TableCell>
                }
                <TerminalTableCell align="center">
                  {dartPoints - (Array.isArray(game.p8_score) && game.p8_score.length > 0 ? game.p8_score.reduce((x, y) => x + y) : 0)}
                </TerminalTableCell>
                {rounds.map((round) => (
                <TableCell key={round}>
                  {getRoundValue(game?.p8_score, round)}
                </TableCell>
                  ))}
              </TableRow>
              } 

            </TableBody>
          </Table>
        </TableContainer>
        }

        {game! && game.final === 0 && final! && 
        <Box textAlign='center' sx={{ p: 2 }}>
          <Button variant={props.darkmode!?"contained":"outlined"}  size="medium" onClick={()=>finalGame(game!.id)} startIcon={<LockIcon />}>{props.english!?"finish":"Abschließen"}</Button>
        </Box>
        }

        {/* <Button variant="outlined" onClick={()=>console.log(game)}>GAME-DATA</Button> */}
        <ScoreDialog
          dialogItems={dialog}
          open={openDialogScore}
          onClose={handleDialogScoreClose}
          darkmode={props.darkmode!}
          english={props.english!}
        />
        <QRDialog
          text={qrText}
          open={openDialogQR}
          onClose={handleDialogQRClose}
        />
    </PageLayout>
  );
};
