import React from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { NavLink } from "react-router-dom";
import logo from "./../assets/redfoxbar.png";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton } from "../buttons/login-button";
import { LogoutButton } from "../buttons/logout-button";
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import ListSubheader from '@mui/material/ListSubheader';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import {SkeeboardPlayerButton} from "../../models/skeeboard"
import Divider from '@mui/material/Divider';
import US from 'country-flag-icons/react/1x1/US'
import DE from 'country-flag-icons/react/1x1/DE'

interface PageFunctionsProp {
  name?:string
  darkmode?:boolean
  handleDarkmode?:()=>void
  english?:boolean
  handleEnglish?:()=>void
  gameHandleShowNames?:()=>void
  gameShowNames?:boolean
  gameHandleDialogQROpen?:()=>void
  gameHandleShowPlayerButton?:(player:number)=>void
  gamePlayerButton?:SkeeboardPlayerButton[]
}

export const NavBar: React.FC<PageFunctionsProp> = ({
    name, 
    darkmode,
    handleDarkmode,
    english,
    handleEnglish,
    gameHandleShowNames, 
    gameShowNames, 
    gameHandleDialogQROpen, 
    gameHandleShowPlayerButton, 
    gamePlayerButton}) => {

  const { isAuthenticated } = useAuth0();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    // <AppBar position="static" style={{background: '#350a06'}}>
    <AppBar position="static">
      <Container maxWidth={false}>
        <Toolbar disableGutters
            sx={{
              display: { xs: "flex" },
              flexDirection: "row",
              justifyContent: "space-between"
            }}>
          {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
          <NavLink to="/" exact 
              style={{
                display: "flex", 
                alignItems: "center", 
                textDecoration: "none",
                color: "#ffffff" 
              }}>
            <img
              className="nav-bar__logo"
              src={logo}
              alt="Redfox Bar Logo"
              width="60"
              height="60"
          />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, marginLeft: 2}}>
            Skeeboard
          </Typography>
          </NavLink>          

          {name! === "game" && (
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="QR-Code">
                <IconButton
                size="large"
                aria-label="QR-Code"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={gameHandleDialogQROpen}
                color="inherit"
              >
                  <QrCode2Icon />
                </IconButton>
            </Tooltip>
          </Box>
          )}

          <Box sx={{ flexGrow: 0 }}>

            <Tooltip title={english?"switch language":"Sprache wechseln"}>
              <IconButton
              size="large"
              aria-label="Sprache wechseln"
              aria-controls="language-change"
              aria-haspopup="true"
              onClick={()=>handleEnglish!()}
              color="inherit"
            >
                {english?<DE style={{width: "20x", height: "20px"}}/>:<US style={{width: "20x", height: "20px"}}/>}
              </IconButton>
            </Tooltip>


            <Tooltip title={english?"open menu":"Menü öffnen"}>
              <IconButton
              size="large"
              aria-label="Auswahlmenü"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
                <MenuIcon />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
            
              {!isAuthenticated && (
                <MenuItem onClick={()=>handleDarkmode!()} sx={{justifyContent: "center"}}>
                  <ListItemText>Darkmode</ListItemText>
                  <Switch
                    size="small"
                    edge="end"
                    checked={darkmode!}
                    inputProps={{
                      'aria-labelledby': 'switch-list-label-bluetooth',
                    }}
                  />                  
                </MenuItem>
              )}
              {/* {!isAuthenticated && (
                <MenuItem onClick={()=>handleEnglish!()} sx={{justifyContent: "center"}}>
                  <ListItemText>English</ListItemText>
                  
                  <Switch
                    size="small"
                    edge="end"
                    checked={english!}
                    inputProps={{
                      'aria-labelledby': 'switch-list-label-bluetooth',
                    }}
                  />                  
                </MenuItem>
              )} */}
              {isAuthenticated && (
                <MenuItem component={NavLink} to={"/searchtable"} sx={{justifyContent: "center"}}>
                  <Typography textAlign="center">
                  Suchtabelle
                  </Typography>
                </MenuItem>             
              )}
              {!isAuthenticated && name! !== "creation" && name! !== "information" && name! !== "history" && (
                <Divider />
              )}


              {name! === "home" &&
                <MenuItem component={NavLink} to={"/information"} sx={{justifyContent: "center"}}>
                  <Typography textAlign="center">
                    Information
                  </Typography>
                </MenuItem>
              }

              {!isAuthenticated && name! === "home" && (
                <MenuItem sx={{justifyContent: "center"}}>
                  <Typography textAlign="center">
                    <LoginButton darkmode={darkmode} />
                  </Typography>
                </MenuItem>
              )}

              {isAuthenticated && name! === "home" && (
                <MenuItem sx={{justifyContent: "center"}}>
                  <Typography textAlign="center">
                    <LogoutButton darkmode={darkmode}/>
                  </Typography>
                </MenuItem>               
              )}

              {name! === "game" && (
                <MenuItem onClick={gameHandleShowNames} sx={{justifyContent: "center"}}>
                  <ListItemText>{english!?"short names":"Namen abkürzen"}</ListItemText>
                  <Switch
                    size="small"
                    edge="end"
                    checked={gameShowNames}
                    inputProps={{
                      'aria-labelledby': 'switch-list-label-bluetooth',
                    }}
                  />                  
                </MenuItem>
              )}
                
              {name! === "game" && gamePlayerButton! && (
                <Divider />
              )}
              {name! === "game" && gamePlayerButton! && (
                <ListSubheader sx={{fontSize: 16, fontWeight: "bold", fontStyle: "italic"}}>{english!?"insert options":"Eingabeoption"}</ListSubheader>
              )}
              {name! === "game" && gamePlayerButton! && gamePlayerButton.map((elem, i) => (
                <MenuItem key={i} onClick={()=>gameHandleShowPlayerButton!(elem.player)} sx={{justifyContent: "center"}}>
                  <ListItemText>{elem.name}</ListItemText>
                  <Switch
                    size="small"
                    edge="end"
                    checked={elem.show}
                    inputProps={{
                      'aria-labelledby': 'switch-list-label-bluetooth',
                    }}
                  />                  
                </MenuItem>
              ))}

            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
