import React from 'react';
import {SkeeboardLocalStorage} from '../../models/skeeboard'

const localStorageName = "skeeboard"

export interface DialogProps {
  open: boolean;
  text: string;
  onClose: () => void;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export function getLocalStorageString():(string | null) {
    return localStorage.getItem(localStorageName);
}

export function getLocalStorage():SkeeboardLocalStorage {
    const lsValue = getLocalStorageString();
    if (lsValue === null) {
        setDefaultLocalStorage();
        return JSON.parse(getLocalStorageString()!);
    }
    return JSON.parse(lsValue!);
}

export function setDefaultLocalStorage() {
    const lsValue:SkeeboardLocalStorage = {
        darkmode: false,
        games: [],
        english: false
    }
    setLocalStorage(lsValue)
}

export function setLocalStorage(lsValue:SkeeboardLocalStorage) {
    localStorage.setItem(localStorageName, JSON.stringify(lsValue));
}