import React from "react";
import { PageLayout } from "../components/page-layout";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import AnimatedText from 'react-animated-text-content';

type Props = {
  darkmode?: boolean
  handleDarkmode?: ()=>void
  english?: boolean
  handleEnglish?: ()=>void
}

export const InformationPage: React.FC<Props> = (props) => {

  return (
    <PageLayout 
      name="information"
      darkmode={props.darkmode!}
      handleDarkmode={props.handleDarkmode!}
      english={props.english!}
      handleEnglish={props.handleEnglish!}
    >
      <Box textAlign='center' sx={{ p: 2 }}>
        <Typography variant="h5" gutterBottom>
        Information
        </Typography>
      </Box>

      {props.english! === false && <>
        <Box textAlign='center' sx={{ p: 2, fontSize: 18 }}>
          Diese privat erstellte Webseite entstand als Freizeitbeschäftigung. 
        </Box>
        <Box textAlign='center' sx={{ p: 2, fontSize: 18 }}>
          Sie soll den Spielablauf für ein Geschicklichkeitsspiel unterstützen.
        </Box>
        <Box textAlign='center' sx={{ p: 2, fontSize: 18 }}>
          Das Spiel kann in der Red Fox Bar in Köln gespielt werden.
        </Box>
        <Box textAlign='center' sx={{ p: 2, fontSize: 18 }}>
          Weitere Informationen über die Bar erhält man auf deren Webseite:
        </Box>
      </>}

      {props.english! === true && <>
        <Box textAlign='center' sx={{ p: 2, fontSize: 18 }}>
          This private website was created as a leisure activity.
        </Box>
        <Box textAlign='center' sx={{ p: 2, fontSize: 18 }}>
          It is intended to support the gameplay of a skill game.
        </Box>
        <Box textAlign='center' sx={{ p: 2, fontSize: 18 }}>
          The game can be played in the Red Fox Bar in Cologne, Germany.
        </Box>
        <Box textAlign='center' sx={{ p: 2, fontSize: 18 }}>
          Further information about the bar can be found on their website:
        </Box>
      </>}

        <Box textAlign='center' sx={{ p: 2 }}>
          <Link 
            href="https://redfoxbar.cologne/" 
            underline="none" 
            target="_blank" 
            rel="noopener noreferrer"
            sx={{fontSize: 30}}>
            <AnimatedText
                type='chars'
                interval={0.04}
                duration={0.3}
                animation={{
                  y: '300px',
                  ease: 'ease',
                  scale: 2,
                }}
              >
                https://redfoxbar.cologne/
            </AnimatedText>
          </Link>
        </Box>

        {props.english! === false && <>
          <Box textAlign='center' sx={{ p: 2, fontSize: 18 }}>
            Die Rechte am eingesetzten Logo der Red Fox Bar liegen beim Inhaber.
          </Box>
        </>}

        {props.english! === true && <>
          <Box textAlign='center' sx={{ p: 2, fontSize: 18 }}>
            The rights to the Red Fox Bar logo used lie with the owner.
          </Box>
        </>}
    </PageLayout>
  );
};
