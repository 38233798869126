import React from "react";
import { PageLayout } from "../components/page-layout";
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

type Props = {
  darkmode?: boolean
  handleDarkmode?: ()=>void
  english?: boolean
  handleEnglish?: ()=>void
}

export const HomePage: React.FC<Props> = (props) => {

  const { isAuthenticated } = useAuth0();

  const getRefer = async () => {
    axios
    .get('/api/env_refer.php')
    .then(response => {
      console.log(response)
    })
    .catch(function(error) {
      console.log(error)
    });
  };

  return (
    <PageLayout 
      name="home"
      darkmode={props.darkmode!}
      handleDarkmode={props.handleDarkmode!}
      english={props.english!}
      handleEnglish={props.handleEnglish!}
    >

      <Box textAlign='center' sx={{ p: 2 }}>
        <Typography variant="h5" gutterBottom>
          {props.english!?"Welcome to the skeeboard!":"Willkommen auf dem Skeeboard!"}
        </Typography>
        {/* <Typography variant="h6" gutterBottom>
          Beta Version - August 2023
        </Typography> */}
      </Box>

      <Box textAlign='center' sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant={props.darkmode!?"contained":"outlined"} 
              size="large" 
              component={Link}
              to="/creation/0">
              {props.english!?"normal game":"Standard Spiel"}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" gutterBottom>
              {props.english!?"normal game with ten rounds":"Normales Spiel über zehn Runden"}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box textAlign='center' sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant={props.darkmode!?"contained":"outlined"} 
              size="large" 
              component={Link}
              to="/creation/1">
              {props.english!?"dart game":"Dart Variante"}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" gutterBottom>
              {props.english!?"points are counted backwards to zero":"Punkte werden rückwärts bis Null runtergezählt"}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {isAuthenticated && (
      <Box textAlign='center' sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant={props.darkmode!?"contained":"outlined"} 
              size="large" 
              component={Link}
              to="/searchtable">
              Suchtabelle
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" gutterBottom>
              Bereich zur Suche und Bearbeitung
            </Typography>
          </Grid>
        </Grid>
      </Box>
      )}

      <Box textAlign='center' sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant={props.darkmode!?"contained":"outlined"}  
              size="large" 
              component={Link}
              to="/history">
              {props.english!?"local history":"Lokale Historie"}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" gutterBottom>
              {props.english!?"currently played games":"Anzeige breits gespielter Spiele"}
            </Typography>
          </Grid>
        </Grid>
      </Box>

    </PageLayout>
  )};
