import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import Button from '@mui/material/Button';

interface Props {
  darkmode?:boolean
}

export const LoginButton: React.FC<Props> = ({darkmode}) => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      prompt: "login",
      appState: {
        returnTo: "/home",
      },
    });
  };

  return (
    <Button variant={darkmode!?"contained":"outlined"} size="small" className="button__login" onClick={handleLogin}>
      Admin Login
    </Button>
  );
};
