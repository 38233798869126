import React from 'react';
import { SkeeboardScore } from '../../models/skeeboard'


export function instanceOfSkeeboardScore(object: any): object is SkeeboardScore {
    return 'r1' in object;
}




