import React, { useEffect, useState } from "react";
import { PageLayout } from "../components/page-layout";
import { useParams } from 'react-router-dom';
import axios from 'axios'
import {
  SkeeboardGame, 
  SkeeboardScore, 
  SkeeboardDialog, 
  SkeeboardPlayerButton
} from '../models/skeeboard'
import { instanceOfSkeeboardScore } from '../components/functions/game'
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import QRDialog from "src/components/dialog/qrdialog";
import Typography from '@mui/material/Typography';
import FunctionsIcon from '@mui/icons-material/Functions';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import Box from '@mui/material/Box';
import LockIcon from '@mui/icons-material/Lock';

type Parameter = {
  ids: string
}

interface sumsAverageType {
  sum1: number;
  sum2: number;
  sum3: number;
  sum4: number;
  sum5: number;
  sum6: number;
  sum7: number;
  sum8: number;
  ave1: number;
  ave2: number;
  ave3: number;
  ave4: number;
  ave5: number;
  ave6: number;
  ave7: number;
  ave8: number;
}
type Props = {
  darkmode?: boolean
  handleDarkmode?: ()=>void
}

export const CompetitionPage: React.FC<Props> = (props) => {
  const [message, setMessage] = useState<string>("");
  const [gameArray, setGameArray] = useState<SkeeboardGame[]>([]);
  const [showNames, setShowNames] = useState<boolean>(true);
  const { ids } = useParams<Parameter>();
  const [rounds, setRounds] = useState<Array<number>>([]);
  const defaultSumAverage = {sum1:0,sum2:0,sum3:0,sum4:0,sum5:0,sum6:0,sum7:0,sum8:0,ave1:0,ave2:0,ave3:0,ave4:0,ave5:0,ave6:0,ave7:0,ave8:0}
  const [sumAveArray, setSumAveArray] = useState<sumsAverageType[]>([])

  const [openDialogScore, setOpenDialogScore] = React.useState<boolean>(false);
  const [openDialogQR, setOpenDialogQR] = React.useState<boolean>(false);
  
  const [dialog, setDialog] = React.useState<SkeeboardDialog>({player: 0, round: 0, value: null, showClear:true});

  const [playerButton, setPlayerButton] = React.useState<SkeeboardPlayerButton[] | undefined>(undefined);
  const [isMobile, setIsMobile] = useState<boolean>((window.innerWidth<720)?true:false);
  const [final, setFinal] = useState<boolean>(false)

  const [refreshBoard, setRefreshBoard] = useState<boolean>(false)

  const qrText = window.location.href;
  const roundsDefault = [1,2,3,4,5,6,7,8,9,10]

  useEffect(() => {
    getGames(true);
  }, [ids]);

  useEffect(() => {
    if (refreshBoard) {
      setRefreshBoard(false)
      //wait one sec for the db update
      setTimeout(() => {getGames(false)}, 1000)
    }
  }, [refreshBoard]);

  useEffect(() => {
    if (gameArray!) {

      if (gameArray.length > 0 && sumAveArray.length === 0 ) {
        gameArray.forEach(() => {
          sumAveArray.push(defaultSumAverage)
        })
      }

      gameArray.forEach(game => {

        setRounds(roundsDefault)
        let finalCounter = 0;
        let finalAim = 0;
        if (game.p1_name! && game.p1_name.length > 0) {
          finalAim += roundsDefault.length
          roundsDefault.forEach(round => {
            if (getRoundValue(game.p1_score, round) !== null) {
              finalCounter += 1
            }
          });
        }
        if (game.p2_name! && game.p2_name.length > 0) {
          finalAim += roundsDefault.length
          roundsDefault.forEach(round => {
            if (getRoundValue(game.p2_score, round) !== null) {
              finalCounter += 1
            }
          });
        }
        if (game.p3_name! && game.p3_name.length > 0) {
          finalAim += roundsDefault.length
          roundsDefault.forEach(round => {
            if (getRoundValue(game.p3_score, round) !== null) {
              finalCounter += 1
            }
          });
        }
        if (game.p4_name! && game.p4_name.length > 0) {
          finalAim += roundsDefault.length
          roundsDefault.forEach(round => {
            if (getRoundValue(game.p4_score, round) !== null) {
              finalCounter += 1
            }
          });
        }
        if (game.p5_name! && game.p5_name.length > 0) {
          finalAim += roundsDefault.length
          roundsDefault.forEach(round => {
            if (getRoundValue(game.p5_score, round) !== null) {
              finalCounter += 1
            }
          });
        }
        if (game.p6_name! && game.p6_name.length > 0) {
          finalAim += roundsDefault.length
          roundsDefault.forEach(round => {
            if (getRoundValue(game.p6_score, round) !== null) {
              finalCounter += 1
            }
          });
        }
        if (game.p7_name! && game.p7_name.length > 0) {
          finalAim += roundsDefault.length
          roundsDefault.forEach(round => {
            if (getRoundValue(game.p7_score, round) !== null) {
              finalCounter += 1
            }
          });
        }
        if (game.p8_name! && game.p8_name.length > 0) {
          finalAim += roundsDefault.length
          roundsDefault.forEach(round => {
            if (getRoundValue(game.p8_score, round) !== null) {
              finalCounter += 1
            }
          });
        }
        if (finalCounter === finalAim) {
          setFinal(true)
        } else {
          setFinal(false)
        }
      })
    }
  }, [gameArray]);

  //alle 10 Sekunden ein Select
  useEffect(() => {
    const interval = setInterval(() => {
      if (!openDialogScore) {
        getGames(false);
      }
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const getGames = (first: boolean) => {
    if (ids! && ids.length > 0 && ids !== "undefined") {

      const idArray = ids.split('_');
      const newGameArray:SkeeboardGame[] = []

      idArray.forEach(function (id) {
        
        const bodyFormData = new FormData();
        bodyFormData.append("id", id);
        axios
        .post('/api/zgkzm6c0aqtn.php', bodyFormData)
        .then(response => {
          const newGame:any = response.data;
          newGame.type = Number(newGame.type)
          newGame.machine = Number(newGame.machine)
          newGame.final = Number(newGame.final)
          if (newGame?.p1_score) {
            newGame.p1_score = newGame.p1_score.replace(/\\/g, '');
            newGame.p1_score = JSON.parse(newGame.p1_score);
            newGame.p1_sum = 0;
          }
          if (newGame?.p2_score) {
            newGame.p2_score = newGame.p2_score.replace(/\\/g, '');
            newGame.p2_score = JSON.parse(newGame.p2_score);
            newGame.p2_sum = 0;
          }
          if (newGame?.p3_score) {
            newGame.p3_score = newGame.p3_score.replace(/\\/g, '');
            newGame.p3_score = JSON.parse(newGame.p3_score);
            newGame.p3_sum = 0;
          }
          if (newGame?.p4_score) {
            newGame.p4_score = newGame.p4_score.replace(/\\/g, '');
            newGame.p4_score = JSON.parse(newGame.p4_score);
            newGame.p4_sum = 0;
          }
          if (newGame?.p5_score) {
            newGame.p5_score = newGame.p5_score.replace(/\\/g, '');
            newGame.p5_score = JSON.parse(newGame.p5_score);
            newGame.p5_sum = 0;
          }
          if (newGame?.p6_score) {
            newGame.p6_score = newGame.p6_score.replace(/\\/g, '');
            newGame.p6_score = JSON.parse(newGame.p6_score);
            newGame.p6_sum = 0;
          }
          if (newGame?.p7_score) {
            newGame.p7_score = newGame.p7_score.replace(/\\/g, '');
            newGame.p7_score = JSON.parse(newGame.p7_score);
            newGame.p7_sum = 0;
          }
          if (newGame?.p8_score) {
            newGame.p8_score = newGame.p8_score.replace(/\\/g, '');
            newGame.p8_score = JSON.parse(newGame.p8_score);
            newGame.p8_sum = 0;
          }

          newGameArray.push(response.data)
          //Summe bilden
          createSumsAverage(newGame);
          if (first && playerButton === undefined && !newGame.final) {
            const newPlayerButton: SkeeboardPlayerButton[] = []
            if (newGame.p1_name! && newGame.p1_name.length > 0) {
              newPlayerButton.push({player:1,name:newGame.p1_name,show:true})
            }
            if (newGame.p2_name! && newGame.p2_name.length > 0) {
              newPlayerButton.push({player:2,name:newGame.p2_name,show:true})
            }
            if (newGame.p3_name! && newGame.p3_name.length > 0) {
              newPlayerButton.push({player:3,name:newGame.p3_name,show:true})
            }
            if (newGame.p4_name! && newGame.p4_name.length > 0) {
              newPlayerButton.push({player:4,name:newGame.p4_name,show:true})
            }
            if (newGame.p5_name! && newGame.p5_name.length > 0) {
              newPlayerButton.push({player:5,name:newGame.p5_name,show:true})
            }
            if (newGame.p6_name! && newGame.p6_name.length > 0) {
              newPlayerButton.push({player:6,name:newGame.p6_name,show:true})
            }
            if (newGame.p7_name! && newGame.p7_name.length > 0) {
              newPlayerButton.push({player:7,name:newGame.p7_name,show:true})
            }
            if (newGame.p8_name! && newGame.p8_name.length > 0) {
              newPlayerButton.push({player:8,name:newGame.p8_name,show:true})
            }
            setPlayerButton(newPlayerButton)
          }  
        })
        .catch(function(error) {
          console.log(error)
        });

      });
      setGameArray(newGameArray)

    }
  }

  const createSumsAverage = (paramGame:SkeeboardGame) => {

    const newSumsAverageArray:sumsAverageType[] = sumAveArray

    let s1:number, s2:number, s3:number, s4:number, s5:number, s6:number, s7:number, s8:number;
    s1 = s2 = s3 = s4 = s5 =s6 = s7 =s8 = 0;
    let a1:number, a2:number, a3:number, a4:number, a5:number, a6:number, a7:number, a8:number;
    a1 = a2 = a3 = a4 = a5 =a6 = a7 =a8 = 0;
    roundsDefault.map((round) => {
      if (paramGame?.p1_score) {
        const score = Number(getRoundValue(paramGame!.p1_score, round));
        if (score!) {
          s1 += score;
          a1++;
        } else {
          s1 += 0;
        }
      }
      if (paramGame?.p2_score) {
        const score = Number(getRoundValue(paramGame!.p2_score, round));
        if (score!) {
          s2 += score;
          a2++;
        } else {
          s2 += 0
        }
      }
      if (paramGame?.p3_score) {
        const score = Number(getRoundValue(paramGame!.p3_score, round));
        if (score!) {
          s3 += score;
          a3++;
        } else {
          s3 += 0
        }
      }
      if (paramGame?.p4_score) {
        const score = Number(getRoundValue(paramGame!.p4_score, round));
        if (score!) {
          s4 += score;
          a4++;
        } else {
          s4 += 0
        }
      }
      if (paramGame?.p5_score) {
        const score = Number(getRoundValue(paramGame!.p5_score, round));
        if (score!) {
          s5 += score;
          a5++;
        } else {
          s5 += 0
        }
      }
      if (paramGame?.p6_score) {
        const score = Number(getRoundValue(paramGame!.p6_score, round));
        if (score!) {
          s6 += score;
          a6++;
        } else {
          s6 += 0
        }
      }
      if (paramGame?.p7_score) {
        const score = Number(getRoundValue(paramGame!.p7_score, round));
        if (score!) {
          s7 += score;
          a7++;
        } else {
          s7 += 0
        }
      }
      if (paramGame?.p8_score) {
        const score = Number(getRoundValue(paramGame!.p8_score, round));
        if (score!) {
          s8 += score;
          a8++;
        } else {
          s8 += 0
        }
      }
    })


    newSumsAverageArray.push({
      sum1: s1, 
      sum2: s2, 
      sum3: s3, 
      sum4: s4, 
      sum5: s5, 
      sum6: s6, 
      sum7: s7, 
      sum8: s8, 
      ave1: a1>0?Number((s1 / a1).toFixed(1)):0, 
      ave2: a2>0?Number((s2 / a2).toFixed(1)):0, 
      ave3: a3>0?Number((s3 / a3).toFixed(1)):0, 
      ave4: a4>0?Number((s4 / a4).toFixed(1)):0, 
      ave5: a5>0?Number((s5 / a5).toFixed(1)):0, 
      ave6: a6>0?Number((s6 / a6).toFixed(1)):0, 
      ave7: a7>0?Number((s7 / a7).toFixed(1)):0, 
      ave8: a8>0?Number((s8 / a8).toFixed(1)):0})
    setSumAveArray(newSumsAverageArray)
  };

/*   
  const updateGame = async (player:number,score:string) => {
    if (game!) {
      const bodyFormData = new FormData();
      bodyFormData.append("id", game.id);
      bodyFormData.append("p_number", String(player));
      bodyFormData.append("p_score", score);
      axios
      .post('/api/zqk2n98wtv6t.php', bodyFormData)
      .then(()=>{
        setRefreshBoard(true);
      })
      .catch(function(error) {
        console.log(error);

      });    
    }
  }; 
 */

  const getRoundValue = (player_score:any, round:number):(number | null) => {
    let key: keyof SkeeboardScore;
    if (instanceOfSkeeboardScore(player_score)) {
      for (key in player_score) {
        if (key === 'r'+String(round)) {
          if (player_score[key] === null) {
            return null;
          }
          // setFinalCounter(finalCounter+1)
          return player_score[key];
        }
      }
    } else {
      if (player_score.length >= round) {
        return player_score[player_score.length - round];
      }
    }
    return null;
  };

  const setRoundValue = (player_score:any, round:number, value:number | null):SkeeboardScore => {
    let key: keyof SkeeboardScore;
    if (instanceOfSkeeboardScore(player_score)) {
      for (key in player_score) {
        if (key === 'r'+String(round)) {
          player_score[key] = value;
          break;
        }
      }
    }
    return player_score;
  };

  const handleShowNames = () => {
    setShowNames(!showNames)
  };

  const finalGame = (id:string) => {
    if (id.length > 0 ) {
      const bodyFormData = new FormData();
      bodyFormData.append("id", id);
      axios
      .post('/api/zd1kyalugn05.php', bodyFormData)
      .then(response => {
        console.log(response.data)
      })
      .catch(function(error) {
        console.log(error)
      });    
    }
  };


  const checkShowPlayerButton = (player:number) => {
    if (playerButton!) {
      const index = playerButton.findIndex(object => {
        return object.player === player;
      });
      if (index !== -1) {
        return playerButton[index].show;
      }
      return false;
    }
    return false;
  };

  const handleShowPlayerButton = (player:number) => {
    if (playerButton!) {
      const newPlayerButton: SkeeboardPlayerButton[] = [...playerButton]
      const index = newPlayerButton.findIndex(object => {
        return object.player === player;
      });
      if (index !== -1) {
        newPlayerButton[index].show = !newPlayerButton[index].show;
      }
      setPlayerButton(newPlayerButton)
    }
  };

  const handleDialogQROpen = () => {
    setOpenDialogQR(true);
  };

  const handleDialogQRClose = () => {
    setOpenDialogQR(false);
  };

  
  //window size listener
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })
  
  return (
    <PageLayout 
      name={"game"} 
      darkmode={props.darkmode!}
      handleDarkmode={props.handleDarkmode!}
      gameHandleShowNames={handleShowNames} 
      gameShowNames={showNames}
      gameHandleDialogQROpen={handleDialogQROpen}
      gameHandleShowPlayerButton={handleShowPlayerButton}
      gamePlayerButton={playerButton} >

      {gameArray.map((game, gameIndex) => (<div key={gameIndex}>
        <Box textAlign='center' sx={{ p: 2 }}>
          <Typography variant="h5" gutterBottom>
            {game!?game!.name:""}
          </Typography>
          {/* <Typography variant="h6" gutterBottom>
            {game!?" "+convertDateTime(game!.datetime,false):""}
          </Typography> */}
        </Box>

        {isMobile && game! && game.type === 0 &&
        <TableContainer component={Paper}>
          <Table aria-label="Punktetabelle">
            <TableHead>
              <TableRow>
                <TableCell>Runde</TableCell>
                {game?.p1_name && game?.p1_name.length > 0 &&
                  <TableCell>{showNames ? game?.p1_name : game?.p1_name.slice(0, 3)}</TableCell>
                }
                {game?.p2_name && game?.p2_name.length > 0 &&
                  <TableCell>{showNames ? game?.p2_name : game?.p2_name.slice(0, 3)}</TableCell>
                }
                {game?.p3_name && game?.p3_name.length > 0 &&
                  <TableCell>{showNames ? game?.p3_name : game?.p3_name.slice(0, 3)}</TableCell>
                }
                {game?.p4_name && game?.p4_name.length > 0 &&
                  <TableCell>{showNames ? game?.p4_name : game?.p4_name.slice(0, 3)}</TableCell>
                }
                {game?.p5_name && game?.p5_name.length > 0 &&
                  <TableCell>{showNames ? game?.p5_name : game?.p5_name.slice(0, 3)}</TableCell>
                }
                {game?.p6_name && game?.p6_name.length > 0 &&
                  <TableCell>{showNames ? game?.p6_name : game?.p6_name.slice(0, 3)}</TableCell>
                }
                {game?.p7_name && game?.p7_name.length > 0 &&
                  <TableCell>{showNames ? game?.p7_name : game?.p7_name.slice(0, 3)}</TableCell>
                }
                {game?.p8_name && game?.p8_name.length > 0 &&
                  <TableCell>{showNames ? game?.p8_name : game?.p8_name.slice(0, 3)}</TableCell>
                }
                {game! && game.final === 0 &&
                <TableCell><div style={{display: 'none' }}>Punktevergabe</div></TableCell>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {rounds.map((round) => (
                <TableRow
                  key={round}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">{round}</TableCell>
                  
                  {game?.p1_name && game?.p1_name.length > 0 &&
                    <TableCell>{getRoundValue(game?.p1_score, round)}</TableCell>
                  }
                  {game?.p2_name && game?.p2_name.length > 0 &&
                    <TableCell>{getRoundValue(game?.p2_score, round)}</TableCell>
                  }
                  {game?.p3_name && game?.p3_name.length > 0 &&
                    <TableCell>{getRoundValue(game?.p3_score, round)}</TableCell>
                  }
                  {game?.p4_name && game?.p4_name.length > 0 &&
                    <TableCell>{getRoundValue(game?.p4_score, round)}</TableCell>
                  }
                  {game?.p5_name && game?.p5_name.length > 0 &&
                    <TableCell>{getRoundValue(game?.p5_score, round)}</TableCell>
                  }
                  {game?.p6_name && game?.p6_name.length > 0 &&
                    <TableCell>{getRoundValue(game?.p6_score, round)}</TableCell>
                  }
                  {game?.p7_name && game?.p7_name.length > 0 &&
                    <TableCell>{getRoundValue(game?.p7_score, round)}</TableCell>
                  }
                  {game?.p8_name && game?.p8_name.length > 0 &&
                    <TableCell>{getRoundValue(game?.p8_score, round)}</TableCell>
                  }
                  {/* {game! && game.final === 0 &&
                  <TableCell>
                    {game?.p1_name && game?.p1_name.length > 0 && checkShowPlayerButton(1) &&
                      <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(1,round,getRoundValue(game?.p1_score, round))}>{showNames ? game?.p1_name : game?.p1_name.slice(0, 3)}</Button>
                    }
                    {game?.p2_name && game?.p2_name.length > 0 && checkShowPlayerButton(2) &&
                      <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(2,round,getRoundValue(game?.p2_score, round))}>{showNames ? game?.p2_name : game?.p2_name.slice(0, 3)}</Button>
                    }
                    {game?.p3_name && game?.p3_name.length > 0 && checkShowPlayerButton(3) &&
                      <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(3,round,getRoundValue(game?.p3_score, round))}>{showNames ? game?.p3_name : game?.p3_name.slice(0, 3)}</Button>
                    }
                    {game?.p4_name && game?.p4_name.length > 0 && checkShowPlayerButton(4) &&
                      <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(4,round,getRoundValue(game?.p4_score, round))}>{showNames ? game?.p4_name : game?.p4_name.slice(0, 3)}</Button>
                    }
                    {game?.p5_name && game?.p5_name.length > 0 && checkShowPlayerButton(5) &&
                      <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(5,round,getRoundValue(game?.p5_score, round))}>{showNames ? game?.p5_name : game?.p5_name.slice(0, 3)}</Button>
                    }
                    {game?.p6_name && game?.p6_name.length > 0 && checkShowPlayerButton(6) &&
                      <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(6,round,getRoundValue(game?.p6_score, round))}>{showNames ? game?.p6_name : game?.p6_name.slice(0, 3)}</Button>
                    }
                    {game?.p7_name && game?.p7_name.length > 0 && checkShowPlayerButton(7) &&
                      <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(7,round,getRoundValue(game?.p7_score, round))}>{showNames ? game?.p7_name : game?.p7_name.slice(0, 3)}</Button>
                    }
                    {game?.p8_name && game?.p8_name.length > 0 && checkShowPlayerButton(8) &&
                      <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(8,round,getRoundValue(game?.p8_score, round))}>{showNames ? game?.p8_name : game?.p8_name.slice(0, 3)}</Button>
                    }
                  </TableCell>
                  } */}
                </TableRow>
              ))}
              <TableRow>
                  <TableCell component="th" scope="row">
                    <FunctionsIcon fontSize="small" />
                  </TableCell>
                  {game?.p1_name && game?.p1_name.length > 0 &&
                    <TableCell>{sumAveArray[gameIndex].sum1}</TableCell>
                  }
                  {game?.p2_name && game?.p2_name.length > 0 &&
                    <TableCell>{sumAveArray[gameIndex].sum2}</TableCell>
                  }
                  {game?.p3_name && game?.p3_name.length > 0 &&
                    <TableCell>{sumAveArray[gameIndex].sum3}</TableCell>
                  }
                  {game?.p4_name && game?.p4_name.length > 0 &&
                    <TableCell>{sumAveArray[gameIndex].sum4}</TableCell>
                  }
                  {game?.p5_name && game?.p5_name.length > 0 &&
                    <TableCell>{sumAveArray[gameIndex].sum5}</TableCell>
                  }
                  {game?.p6_name && game?.p6_name.length > 0 &&
                    <TableCell>{sumAveArray[gameIndex].sum6}</TableCell>
                  }
                  {game?.p7_name && game?.p7_name.length > 0 &&
                    <TableCell>{sumAveArray[gameIndex].sum7}</TableCell>
                  }
                  {game?.p8_name && game?.p8_name.length > 0 &&
                    <TableCell>{sumAveArray[gameIndex].sum8}</TableCell>
                  }
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <EqualizerIcon fontSize="small" />
                  </TableCell>
                  {game?.p1_name && game?.p1_name.length > 0 &&
                    <TableCell>{sumAveArray[gameIndex].ave1}</TableCell>
                  }
                  {game?.p2_name && game?.p2_name.length > 0 &&
                    <TableCell>{sumAveArray[gameIndex].ave2}</TableCell>
                  }
                  {game?.p3_name && game?.p3_name.length > 0 &&
                    <TableCell>{sumAveArray[gameIndex].ave3}</TableCell>
                  }
                  {game?.p4_name && game?.p4_name.length > 0 &&
                    <TableCell>{sumAveArray[gameIndex].ave4}</TableCell>
                  }
                  {game?.p5_name && game?.p5_name.length > 0 &&
                    <TableCell>{sumAveArray[gameIndex].ave5}</TableCell>
                  }
                  {game?.p6_name && game?.p6_name.length > 0 &&
                    <TableCell>{sumAveArray[gameIndex].ave6}</TableCell>
                  }
                  {game?.p7_name && game?.p7_name.length > 0 &&
                    <TableCell>{sumAveArray[gameIndex].ave7}</TableCell>
                  }
                  {game?.p8_name && game?.p8_name.length > 0 &&
                    <TableCell>{sumAveArray[gameIndex].ave8}</TableCell>
                  }
                </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        }

        {!isMobile && game! && game.type === 0 &&
        <TableContainer component={Paper}>
          <Table aria-label="Punktetabelle">
            <TableHead>
              <TableRow>
                <TableCell>Runde</TableCell>

                {rounds.map((round) => (
                  <TableCell component="th" scope="row" key={round}>{round}</TableCell>
                ))}
                <TableCell><FunctionsIcon fontSize="small" /></TableCell>
                <TableCell><EqualizerIcon fontSize="small" /></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {game?.p1_name && game?.p1_name.length > 0 &&
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                
                  <TableCell component="th" scope="row">{showNames ? game?.p1_name : game?.p1_name.slice(0, 3)}</TableCell>
                
                  {rounds.map((round) => (
                  <TableCell key={round}>{getRoundValue(game?.p1_score, round)}</TableCell>
                  ))}
                  <TableCell>{sumAveArray[gameIndex].sum1}</TableCell>
                  <TableCell>{sumAveArray[gameIndex].ave1}</TableCell>
                </TableRow>
              }

              {game?.p2_name && game?.p2_name.length > 0 &&
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                
                  <TableCell component="th" scope="row">{showNames ? game?.p2_name : game?.p2_name.slice(0, 3)}</TableCell>
                
                  {rounds.map((round) => (
                  <TableCell key={round}>{getRoundValue(game?.p2_score, round)}</TableCell>
                  ))}
                  <TableCell>{sumAveArray[gameIndex].sum2}</TableCell>
                  <TableCell>{sumAveArray[gameIndex].ave2}</TableCell>
                </TableRow>
              }

              {game?.p3_name && game?.p3_name.length > 0 &&
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                
                  <TableCell component="th" scope="row">{showNames ? game?.p3_name : game?.p3_name.slice(0, 3)}</TableCell>
                
                  {rounds.map((round) => (
                  <TableCell key={round}>{getRoundValue(game?.p3_score, round)}</TableCell>
                  ))}
                  <TableCell>{sumAveArray[gameIndex].sum3}</TableCell>
                  <TableCell>{sumAveArray[gameIndex].ave3}</TableCell>
                </TableRow>
              }

              {game?.p4_name && game?.p4_name.length > 0 &&
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                
                  <TableCell component="th" scope="row">{showNames ? game?.p4_name : game?.p4_name.slice(0, 3)}</TableCell>
                
                  {rounds.map((round) => (
                  <TableCell key={round}>{getRoundValue(game?.p4_score, round)}</TableCell>
                  ))}
                  <TableCell>{sumAveArray[gameIndex].sum4}</TableCell>
                  <TableCell>{sumAveArray[gameIndex].ave4}</TableCell>
                </TableRow>
              }

              {game?.p5_name && game?.p5_name.length > 0 &&
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                
                  <TableCell component="th" scope="row">{showNames ? game?.p5_name : game?.p5_name.slice(0, 3)}</TableCell>
                
                  {rounds.map((round) => (
                  <TableCell key={round}>{getRoundValue(game?.p5_score, round)}</TableCell>
                  ))}
                  <TableCell>{sumAveArray[gameIndex].sum5}</TableCell>
                  <TableCell>{sumAveArray[gameIndex].ave5}</TableCell>
                </TableRow>
              }

              {game?.p6_name && game?.p6_name.length > 0 &&
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                
                  <TableCell component="th" scope="row">{showNames ? game?.p6_name : game?.p6_name.slice(0, 3)}</TableCell>
                
                  {rounds.map((round) => (
                  <TableCell key={round}>{getRoundValue(game?.p6_score, round)}</TableCell>
                  ))}
                  <TableCell>{sumAveArray[gameIndex].sum6}</TableCell>
                  <TableCell>{sumAveArray[gameIndex].ave6}</TableCell>
                </TableRow>
              }

              {game?.p7_name && game?.p7_name.length > 0 &&
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                
                  <TableCell component="th" scope="row">{showNames ? game?.p7_name : game?.p7_name.slice(0, 3)}</TableCell>
                
                  {rounds.map((round) => (
                  <TableCell key={round}>{getRoundValue(game?.p7_score, round)}</TableCell>
                  ))}
                  <TableCell>{sumAveArray[gameIndex].sum7}</TableCell>
                  <TableCell>{sumAveArray[gameIndex].ave7}</TableCell>
                </TableRow>
              }

              {game?.p8_name && game?.p8_name.length > 0 &&
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                
                  <TableCell component="th" scope="row">{showNames ? game?.p8_name : game?.p8_name.slice(0, 3)}</TableCell>
                
                  {rounds.map((round) => (
                  <TableCell key={round}>{getRoundValue(game?.p8_score, round)}</TableCell>
                  ))}
                  <TableCell>{sumAveArray[gameIndex].sum8}</TableCell>
                  <TableCell>{sumAveArray[gameIndex].ave8}</TableCell>
                </TableRow>
              }

              {game! && game.final === 0 &&
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              
                <TableCell></TableCell>
              
                {/* {rounds.map((round) => (
                  <TableCell key={round}>
                  {game?.p1_name && game?.p1_name.length > 0 && checkShowPlayerButton(1) &&
                    <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(1,round,getRoundValue(game?.p1_score, round))}>{showNames ? game?.p1_name : game?.p1_name.slice(0, 3)}</Button>
                  }
                  {game?.p2_name && game?.p2_name.length > 0 && checkShowPlayerButton(2) &&
                    <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(2,round,getRoundValue(game?.p2_score, round))}>{showNames ? game?.p2_name : game?.p2_name.slice(0, 3)}</Button>
                  }
                  {game?.p3_name && game?.p3_name.length > 0 && checkShowPlayerButton(3) &&
                    <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(3,round,getRoundValue(game?.p3_score, round))}>{showNames ? game?.p3_name : game?.p3_name.slice(0, 3)}</Button>
                  }
                  {game?.p4_name && game?.p4_name.length > 0 && checkShowPlayerButton(4) &&
                    <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(4,round,getRoundValue(game?.p4_score, round))}>{showNames ? game?.p4_name : game?.p4_name.slice(0, 3)}</Button>
                  }
                  {game?.p5_name && game?.p5_name.length > 0 && checkShowPlayerButton(5) &&
                    <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(5,round,getRoundValue(game?.p5_score, round))}>{showNames ? game?.p5_name : game?.p5_name.slice(0, 3)}</Button>
                  }
                  {game?.p6_name && game?.p6_name.length > 0 && checkShowPlayerButton(6) &&
                    <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(6,round,getRoundValue(game?.p6_score, round))}>{showNames ? game?.p6_name : game?.p6_name.slice(0, 3)}</Button>
                  }
                  {game?.p7_name && game?.p7_name.length > 0 && checkShowPlayerButton(7) &&
                    <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(7,round,getRoundValue(game?.p7_score, round))}>{showNames ? game?.p7_name : game?.p7_name.slice(0, 3)}</Button>
                  }
                  {game?.p8_name && game?.p8_name.length > 0 && checkShowPlayerButton(8) &&
                    <Button variant={props.darkmode!?"contained":"outlined"}  size="small" onClick={()=>handleDialogScoreOpen(8,round,getRoundValue(game?.p8_score, round))}>{showNames ? game?.p8_name : game?.p8_name.slice(0, 3)}</Button>
                  }
                </TableCell>
                ))} */}
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
        }

        {game! && game.final === 0 && final! && 
        <Box textAlign='center' sx={{ p: 2 }}>
          <Button variant={props.darkmode!?"contained":"outlined"}  size="medium" onClick={()=>finalGame(game!.id)} startIcon={<LockIcon />}>Abschließen</Button>
        </Box>
        }

        
      </div>))}

        <QRDialog
          text={qrText}
          open={openDialogQR}
          onClose={handleDialogQRClose}
        />
    </PageLayout>
  );
};
