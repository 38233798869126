import React from "react";
import logo from "./assets/loading.svg"

export const PageLoader: React.FC = () => {

  return (
    <div className="loader">
      <img src={logo} alt="Loading..." />
    </div>
  );
};
