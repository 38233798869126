import React from "react";
import { createTheme } from '@mui/material/styles';

const lightRed = '#350a06';
const darkBackground = "#141414";
const darkMainColor = "#9e4613";
const nearlyWhite = "#ededed";
const disabledgrey = "#787878";
const lightShadow = "0px 5px 5px -3px rgb(200 200 200 / 20%), 0px 8px 10px 1px rgb(200 200 200 / 14%), 0px 3px 14px 2px rgb(200 200 200 / 12%)";

export const whitemodeTheme = createTheme({
    palette: {
        primary: {
        // light: will be calculated from palette.primary.main,
        main: lightRed,
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
        light: '#0066ff',
        main: '#0044ff',
        // dark: will be calculated from palette.secondary.main,
        contrastText: '#ffcc00',
        },
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
        background: {
          default: "#ffffff"
        },
        text: {
          primary: "#000000"
        }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderWidth: 2,
            "&:focus": {
              borderWidth: 2,
            },  
            "&:active": {
              borderWidth: 2,
            },    
            "&:select": {
              borderWidth: 2,
            },       
            "&:hover": {
              borderWidth: 2,
            },  
            margin: 1      
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            textAlign: "center"
          }
        }
      },
      MuiSvgIcon: {
        styleOverrides: {
          colorSecondary: {
            fill: lightRed,
          },
        },
      },
    }  
  });
  
export const darkmodeTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
        // light: will be calculated from palette.primary.main,
        main: darkMainColor,
        contrastText: "#fff"
        
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
        light: '#0066ff',
        main: '#0044ff',
        // dark: will be calculated from palette.secondary.main,
        contrastText: '#ffcc00',
        },
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
        background: {
          default: darkBackground
        },
        text: {
          primary: nearlyWhite
        }, 
        action: {
          disabledBackground: disabledgrey,
          disabled: disabledgrey
        },
        common: {
          
        }  
    },
    components: {
      // CTRL + SPACE to find the component you would like to override.
      // For most of them you will need to adjust just the root...
      MuiTextField: {
        styleOverrides: {
          root: {
            '& label': {
              color: nearlyWhite,
            },
            '& label.Mui-focused': {
              color: nearlyWhite,
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: nearlyWhite,
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: nearlyWhite,
              },
              '&:hover fieldset': {
                borderColor: nearlyWhite,
                borderWidth: '0.15rem',
              },
              '&.Mui-focused fieldset': {
                borderColor: nearlyWhite,
              },
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: darkMainColor,
            backgroundImage: darkMainColor
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fill: nearlyWhite,
          },
          colorSecondary: {
            fill: darkMainColor,
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            textTransform: 'initial',
            fontSize: '1rem',
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            color: nearlyWhite,
            background: darkBackground
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: nearlyWhite,
            background: darkBackground,
            borderRight: 1,   
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: nearlyWhite,
            background: darkBackground            
          }
        }
      },
      MuiTable: {
        styleOverrides: {
          root: {
            color: nearlyWhite,
            background: darkBackground
          }
        }
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            color: nearlyWhite,
            background: darkBackground
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            color: nearlyWhite,
            background: darkBackground,
            textAlign: "center"
          }
        }
      },
      MuiMenu: {
        styleOverrides: {
          root: {
            color: nearlyWhite,
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: nearlyWhite,
            background: darkBackground
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            color: nearlyWhite,
            boxShadow: lightShadow
          }
        }
      },
      MuiList: {
        styleOverrides: {
          root: {
            color: nearlyWhite,
            background: darkBackground
          }
        }
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            color: nearlyWhite,
            background: darkBackground
          }
        }
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            background: nearlyWhite
          }
        }
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            color: nearlyWhite,
          }
        }
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            color: nearlyWhite,
            background: darkBackground
          }
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            color: nearlyWhite,
            background: darkBackground
          }
        }
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: nearlyWhite,
            background: darkBackground
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            margin: 1      
          },
        },
      },
    },
  });
