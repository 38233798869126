import React, { useEffect, useState } from "react";
import { CodeSnippet } from "../components/code-snippet";
import { PageLayout } from "../components/page-layout";

type Props = {
  darkmode?: boolean
  handleDarkmode?: ()=>void
}

export const PublicPage: React.FC<Props> = (props) => {

  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    let isMounted = true;

    const getMessage = async () => {
      // const { data, error } = await getPublicResource();
      const data: any = "Test";
      const error: Error = new Error();

      if (!isMounted) {
        return;
      }

      if (data) {
        // setMessage(JSON.stringify(data, null, 2));
        setMessage("TddEddST");
      }

      if (error) {
        setMessage(JSON.stringify(error, null, 2));
      }
    };

    // getMessage();
    setMessage("Test");

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <PageLayout 
      name="public"
      darkmode={props.darkmode!}
      handleDarkmode={props.handleDarkmode!}
    >
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Public Page
        </h1>
        <div className="content__body">
          <p id="page-description">
            <span>
              This page retrieves a <strong>public message</strong> from an
              external API.
            </span>
            <span>
              <strong>Any visitor can access this page.</strong>
            </span>
          </p>
          <CodeSnippet title="Public Message" code={message} />
        </div>
      </div>
    </PageLayout>
  );
};
