import React from "react";
import QRCode from "react-qr-code";

interface qrProp {
    text: string;
}

export const GameQRCode: React.FC<qrProp> = ({text}) => {

  return (
    <QRCode value={text} />
  );
};
